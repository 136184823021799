import { DatePipe } from '@angular/common';
import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { ApiService } from 'src/app/api-service/api.service';
import { AppState } from 'src/app/stores/app/app.state';
import { Course } from 'src/app/stores/course/course.model';
import { Employee } from 'src/app/stores/employee/employee.model';
import { SetCourseHistory, SetEmployeeHistory } from 'src/app/stores/history/history.actions';
import { History } from 'src/app/stores/history/history.model';
import { selectCourseHistory, selectEmployeeHistory, selectEmployeeOfHistory } from 'src/app/stores/history/history.selector';
import { UserService } from 'src/app/user-service/user.service';
import { PusherService } from './pusher.service';

@Injectable()
export class HistoryService {
    public selectedHistory: {course: Course, list: History[]};
    closeEmployeeListener: () => void;
    closeCourseListener: () => void;

    constructor(private _store: Store<AppState>, private api: ApiService, private userService: UserService, private pusherService: PusherService){}
    clearStore(): void{
        this._store.dispatch(new SetEmployeeHistory({employee: null, list: []}));
        this._store.dispatch(new SetCourseHistory({course: null, list: []}));
    }
    setEmployee(emp:Employee){
        this._store.dispatch(new SetEmployeeHistory({employee: null, list: []}))
        this.selectedHistory = null;
        if (emp){
            this.pusherService.registerChangeDetecter('history-employee', emp.id).subscribe(time => {
                this.api.getEmployeeHistoryFromBackend(emp.id).subscribe(list => this._store.dispatch(new SetEmployeeHistory({employee: emp, list: list})));
            });
        }
        // if (emp){
        //     this.api.getEmployeeHistoryFromBackend(emp.id).subscribe(list => this._store.dispatch(new SetEmployeeHistory({employee: emp, list: list})));
        //     this.closeEmployeeListener = this.api.connectToEmployeeHistoryNotification(emp.id,() => {
        //         this.api.getEmployeeHistoryFromBackend(emp.id).subscribe(list => this._store.dispatch(new SetEmployeeHistory({employee: emp, list: list})));
        //     });
        // } else {
        //     if (this.closeEmployeeListener){
        //         this.closeEmployeeListener();
        //     }
        // }
    }
    setCourse(course:Course){
        this._store.dispatch(new SetCourseHistory({course: null, list: []}))
        this.pusherService.registerChangeDetecter('history-course', course.id).subscribe(time => {
            this.api.getCourseHistoryFromBackend(course.id).subscribe(list => this._store.dispatch(new SetCourseHistory({course: course, list: list})));
        });
        // if (course){
        //     this.api.getCourseHistoryFromBackend(course.id).subscribe(list => this._store.dispatch(new SetCourseHistory({course: course, list: list})));
        //     this.closeCourseListener = this.api.connectToCourseHistoryNotification(course.id,() => {
        //         this.api.getCourseHistoryFromBackend(course.id).subscribe(list => this._store.dispatch(new SetCourseHistory({course: course, list: list})));
        //     });
        // } else {
        //     if (this.closeCourseListener){
        //         this.closeCourseListener();
        //     }
        // }
    }
    getEmployeeHistory(): Observable<{course:Course,list: History[]}[]>{
        return this._store.pipe(select(selectEmployeeHistory));
    }
    getCourseHistory(): Observable<{employee:Employee,last: History}[]>{
        return this._store.pipe(select(selectCourseHistory));
    }
    isEmployeeHistoryResolved(): Observable<Employee>{
        return this._store.pipe(select(selectEmployeeOfHistory));
    }
    getHtmlCertificate(emp:Employee, page2: boolean){
        let last = this.selectedHistory.list[0];
        let score:number = last.score;//course.tests[0].Test.score;
        let passString:string = last.pass ? "עבר" : "לא עבר";//course.tests[0].Test.score >= course.pass_score ? "עבר" : "לא עבר";
        let date:string = (new DatePipe('he')).transform(last.date,'dd.MM.yy');//this.datePipe.transform(course.tests[0].date,'dd.MM.yy');
        let department = emp.department ? emp.department : '';
        let role = emp.role ? emp.role : '';
        let htmlDocument;
        if (this.selectedHistory.course.id < 10) {
            htmlDocument = `
            <html>
                <head>
                <title>הדפסת תעודה</title>
                <style>
                    .img1{position: absolute;top: 0px;width: 1175px;height:800px;z-index: -1;}
                    .img2{position: absolute;width: 160px;left: 107px;top: 346px;}
                    .position {*{margin:0;}}
                    .font{overflow:auto;font-size:25px;font-family: Sans-Serif;text-align: center;font-weight: bold;}
                    .font3{overflow:auto;font-size:25px;font-family: Sans-Serif;text-align: center;font-weight: bold;}
                    .font1{overflow:auto;font-size:25px;font-family: Sans-Serif;text-align: center;}
                    .font2{overflow:auto;font-size:16px;font-family: Sans-Serif;text-align: center;}
                    @media print{@page {size: landscape}}
                </style>
                </head>
                <body>
                <div id="position">
                    <img class="img1" src="https://panel.momenteken-college.co.il/${this.userService.company_id}/${this.selectedHistory.course.id}/cert/cert.jpg"/>
                    <img class="img2" src="${this.userService.clientLogoUrl}"/>
                    <div id="name" class="font3" style="direction:rtl;position: absolute;top:303px;left:0;width:850px;text-align: center;">${emp.surname} ${emp.firstName}</div>
                    <div id="_id" class="font" style="position: absolute;top:366px;left:0;width:850px;text-align: center;">${emp.identity}</div>
                    <div id="job" class="font" style="position: absolute;top:430px;left:0;width:850px;text-align: center;">${role}</div>
                    <div id="company" class="font" style="position: absolute;top:493px;left:0;width:850px;text-align: center;">${department}</div>
                    <div id="score_percent" class="font1" style="position: absolute;top: 561px;left:0;width:850px;text-align: center;">${passString}</div>
                    <!--<div id="score_points" class="font2" style="position: absolute;top: 590px;left:0;width:850px;text-align: center;">(${score} points)</div>-->
                    <div class="font1" style="position: absolute;top:621px;left:0;width:850px;text-align: center;">${date}</div>
                </div>
                </body>
            </html>
            `;// onload="window.print();window.close();"
        } else if (this.userService.company_id == '515267490' && (this.selectedHistory.course.id == 501 || this.selectedHistory.course.id == 601)) {
            htmlDocument = `
            <html>
                <head>
                <title>הדפסת תעודה</title>
                <style>
                    .img1{position: absolute;top: 0px;width: 1175px;height:800px;z-index: -1;}
                    .img2{position: absolute;width: 240px;left: 731px;top: 50px;}
                    .position {*{margin:0;}}
                    .font{overflow:auto;font-size:25px;font-family: Sans-Serif;text-align: center;font-weight: bold;}
                    .font3{overflow:auto;font-size:25px;font-family: Sans-Serif;text-align: center;font-weight: bold;}
                    .font1{overflow:auto;font-size:25px;font-family: Sans-Serif;text-align: center;}
                    .font2{overflow:auto;font-size:16px;font-family: Sans-Serif;text-align: center;}
                    @media print{@page {size: landscape}}
                </style>
                </head>
                <body>
                <div id="position">
                    <img class="img1" src="https://panel.momenteken-college.co.il/${this.userService.company_id}/${this.selectedHistory.course.id}/cert/cert.jpg"/>
                    <img class="img2" src="${this.userService.clientLogoUrl}"/>
                    <div id="name" class="font3" style="direction:rtl;position: absolute;top:318px;left:0;width:850px;text-align: center;">${emp.surname} ${emp.firstName}</div>
                    <div id="_id" class="font" style="position: absolute;top:381px;left:0;width:850px;text-align: center;">${emp.identity}</div>
                    <div id="job" class="font" style="position: absolute;top:445px;left:0;width:850px;text-align: center;">${role}</div>
                    <div id="company" class="font" style="position: absolute;top:508px;left:0;width:850px;text-align: center;">${department}</div>
                    <div id="score_percent" class="font1" style="position: absolute;top: 576px;left:0;width:850px;text-align: center;">${passString}</div>
                    <!--<div id="score_points" class="font2" style="position: absolute;top: 590px;left:0;width:850px;text-align: center;">(${score} points)</div>-->
                    <div class="font1" style="position: absolute;top:636px;left:0;width:850px;text-align: center;">${date}</div>
                </div>
                </body>
            </html>
            `;// onload="window.print();window.close()"
        } else {
            htmlDocument = `
            <html>
                <head>
                    <title>הדפסת תעודה</title>
                    <style>
                    .img1{width: 1175px;height:800px;z-index: -1;}
                    .img2{position: absolute;width: 240px;left: 731px;top: 50px;}
                    .img3{width: 1175px;height:800px;z-index: -1;}
                    .position {*{margin:0;}}
                    .ellipsis {overflow: hidden; text-overflow: ellipsis; white-space: nowrap;}
                    @media print{@page {size: landscape}}
                    </style>
                </head>
                <body style="font-family: 'Spoiler';font-size: 35px;margin: 0;">
                    ${this.rawHtmlForGeneralCertificate(emp, role, passString, score, date, page2)}
                </body>
            </html>
        `;
        }
        return htmlDocument;
    }
    isCertificateHasTwoPages(){
        let subject: Subject<boolean> = new Subject();
        this.api.resourceExist(`${this.api.ROOT_URL}/${this.userService.company_id}/${this.selectedHistory.course.id}/cert/cert2.jpg`)
        .subscribe(found => {
            subject.next(true);
        }, err => {
            subject.next(false)
        });
        return subject;
    }
    rawHtmlForGeneralCertificate(emp:Employee, role: string, passString: string, score: number, date: string, page2: boolean): string {
        if (page2){
            return `
            <div id="position">
                <img class="img1" src="${this.api.ROOT_URL}/${this.userService.company_id}/${this.selectedHistory.course.id}/cert/cert.jpg"/>
                <div id="name" class="font3" style="direction:rtl;position: absolute;top:262px;right:660px;">${emp.surname} ${emp.firstName}</div>
                <div id="_id" class="font" style="position: absolute;top:326px;right:660px;">${emp.identity}</div>
                <div id="job" class="font" style="position: absolute;top:391px;right:660px;">${role}</div>
                <div id="company" class="font" style="position: absolute;top:454px;right:660px;">${emp.site}</div>
                <!--<div id="score_percent" class="font1" style="position: absolute;top: 519px;right:660px;">${passString}</div>-->
                <div id="score_points" class="font2" style="position: absolute;top: 519px;right:660px;">${score}</div>
                <div class="font1" style="position: absolute;top:581px;right:660px;">${date}</div>
                <img class="img3" src="${this.api.ROOT_URL}/${this.userService.company_id}/${this.selectedHistory.course.id}/cert/cert2.jpg"/>
                <div class="font3 ellipsis" style="direction: rtl; position: absolute; top: 1140px; right: 47px; width: 210px; font-size: 25px;">${emp.surname} ${emp.firstName}</div>
                <div class="font ellipsis" style="direction: rtl; position: absolute; top: 1172px; right: 47px; width: 210px; font-size: 25px;">${emp.identity}</div>
            </div>
            `;
        } else {
            return `
            <div id="position">
                <img class="img1" style="position: absolute;top: 0;" src="${this.api.ROOT_URL}/${this.userService.company_id}/${this.selectedHistory.course.id}/cert/cert.jpg"/>
                <div id="name" class="font3" style="direction:rtl;position: absolute;top:262px;right:660px;">${emp.surname} ${emp.firstName}</div>
                <div id="_id" class="font" style="position: absolute;top:326px;right:660px;">${emp.identity}</div>
                <div id="job" class="font" style="position: absolute;top:391px;right:660px;">${role}</div>
                <div id="company" class="font" style="position: absolute;top:454px;right:660px;">${emp.site}</div>
                <!--<div id="score_percent" class="font1" style="position: absolute;top: 519px;right:660px;">${passString}</div>-->
                <div id="score_points" class="font2" style="position: absolute;top: 519px;right:660px;">${score}</div>
                <div class="font1" style="position: absolute;top:581px;right:660px;">${date}</div>
            </div>
            `;
        }
    }
}