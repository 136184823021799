import { AssignState, initialAssignState } from './assign.state';
import { AssignAction, AssignActions } from './assign.actions'

export const assignReducer = (
    state = initialAssignState,
    action: AssignActions
): AssignState => {
    switch (action.type) {
        case AssignAction.EditCourseAssigns: {
            return {
                ...state,
                course: {
                    subject: state.course.subject,
                    list: [
                        ...state.course.list.filter(assign => assign.sent),
                        ...action.payload
                    ]
                }
            };
        }
        case AssignAction.RemoveEmployeeAssign: {
            return {
                ...state,
                employee: {
                    subject: state.employee.subject,
                    list: state.employee.list.filter(assign => assign.id != action.payload)
                }
            };
        }
        case AssignAction.SendCourseAssigns: {
            return {
                ...state,
                course: {
                    subject: state.course.subject,
                    list: state.course.list.map(assign => assign.sent ? assign : {...assign, sent: new Date() } )
                }
            };
        }
        case AssignAction.SetCourseAssign: {
            return {
                ...state,
                course: {
                    subject: action.payload.course,
                    list: action.payload.list
                }
            };
        }
        case AssignAction.SetEmployeeAssign: {
            return {
                ...state,
                employee: {
                    subject: action.payload.employee,
                    list: action.payload.list
                }
            };
        }
        default: {
            return state;
        }
    }
}