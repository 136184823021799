import { ModuleWithProviders } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AssignChangesGuard } from './assign-changes-guard/assign-changes.guard';
import { CourseAssignComponent } from './course-assign/course-assign.component';
import { EmployeeArchiveComponent } from './employee-archive/employee-archive.component';
import { EmployeeChangesGuard } from './employee-changes-guard/employee-changes-guard.guard';
import { EmployeeEditComponent } from './employee-edit/employee-edit.component';
import { EmployeeListComponent } from './employee-list/employee-list.component';
import { MainComponent } from './main/main.component';

export const routes: Routes = [
  { path: '', component: MainComponent,
    children: [
      { path: 'list', component: EmployeeListComponent },
      { path: 'archive', component: EmployeeArchiveComponent },
      { path: 'edit/:e_id', component: EmployeeEditComponent, canDeactivate: [EmployeeChangesGuard] },
      { path: 'add', component: EmployeeEditComponent, canDeactivate: [EmployeeChangesGuard] },
      { path: 'assign/:c_id', component: CourseAssignComponent, canDeactivate: [AssignChangesGuard] },
      { path: 'assign', component: CourseAssignComponent },
      { path: '', component: EmployeeListComponent }
    ]
  },
];

export const routing: ModuleWithProviders<RouterModule> = RouterModule.forChild(routes);