import { HttpEvent, HttpEventType, HttpResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { ApiAccessService } from '../api-access.service';
import { shareService } from '../language.service';

enum Truck {
  Pallet = 'משאית פלטה',
  Tanker = 'מיכלית',
  Trailer = 'טריילר/אמבטיה'
}

@Component({
  selector: 'app-select-truck',
  templateUrl: './select-truck.component.html',
  styleUrls: ['./select-truck.component.scss']
})
export class SelectTruckComponent implements OnInit {

  WEBSITE_URL;
  openingAudioUrl: string;
  clientLogoUrl: string;
  palletAudioUrl: string;
  tankerAudioUrl: string;
  trailerAudioUrl: string;
  
  selectedTruckType: Truck;

  attachmentInput: any;

  whatTypeOfTruckDoYouDrive: string
  approvalOfworkInHeightShouldBeAtteched: string;
  nextBtnText: string;
  sizeMaximumText: string;
  chooseAtruckType: string;

  Truck = Truck;

  constructor(private share: shareService, private api: ApiAccessService, private router: Router, private route: ActivatedRoute) {
    // const domain = window.location.hostname;
    // if (domain === 'localhost' || domain === '127.0.0.1' || domain === '192.168.200.10'){
    //   this.WEBSITE_URL = api.API_URL;
    // } else {
      this.WEBSITE_URL = `https://${window.location.hostname}/oate`;
    // }
  }

  ngOnInit(): void {
    if (!this.share.company$.value){
      this.router.navigate(['../'], {relativeTo: this.route});
    }
    let lang = this.share.language$.value.id;
    if (lang === 'ar'){
      this.whatTypeOfTruckDoYouDrive = 'ما نوع الشاحنة التي تقودها؟';
      this.approvalOfworkInHeightShouldBeAtteched = 'يجب إرفاق صورة من تصريح العمل على مرتفعات';
      this.nextBtnText = 'التالي';
      this.sizeMaximumText = 'الحد الأقصى لحجم الملف: 6MB';
      this.chooseAtruckType = 'اختيار نوع شاحنة';
    } else {
      this.whatTypeOfTruckDoYouDrive = 'באיזה סוג משאית אתה נוהג?';
      this.approvalOfworkInHeightShouldBeAtteched = 'יש לצרף צילום אישור עבודה בגובה';
      this.nextBtnText = 'המשך';
      this.sizeMaximumText = 'גודל קובץ מקסימלי: 6MB';
      this.chooseAtruckType = 'יש לבחור סוג משאית';
    }
    this.openingAudioUrl = `${this.WEBSITE_URL}/${this.share.courseId$.value}/${lang}/openingAudioUrl_${lang}.mp3`;
    this.clientLogoUrl = `${this.WEBSITE_URL}/${this.share.courseId$.value}/images/logo.png`;
    this.palletAudioUrl = `${this.WEBSITE_URL}/${this.share.courseId$.value}/${lang}/audio-pallet_${lang}.mp3`;
    this.tankerAudioUrl = `${this.WEBSITE_URL}/${this.share.courseId$.value}/${lang}/audio-tanker_${lang}.mp3`;
    this.trailerAudioUrl = `${this.WEBSITE_URL}/${this.share.courseId$.value}/${lang}/audio-trailer_${lang}.mp3`;
  }

  uploadEducationImage(event:any){
    let reader = new FileReader();
    reader.readAsDataURL(event.target.files[0]);
    reader.addEventListener('load', (event1: any) => {
      const result = event1.target.result;
      const sixMBbytes = 6291456;
      const base64size = sixMBbytes * 1.3333333; // base 64 encode has extra byte for every 3 byte
      if (result.length  > base64size){
        alert(this.sizeMaximumText)
      } else {
        this.attachmentInput = result;
      }
    });
  }

  startSession(){
    let messages:string[] = [];
    if (!this.selectedTruckType){
      messages.push(this.chooseAtruckType);
    } else if (this.selectedTruckType === this.Truck.Tanker && !this.attachmentInput){
      messages.push(this.approvalOfworkInHeightShouldBeAtteched);
    }
    if (messages.length > 0){
      alert(messages.join('\n'));
      return;
    }    
    let httpObservable: Observable<HttpEvent<string>>;
    let body = {
      idnum: this.share.idnum$.value,
      role: `נהג ${this.selectedTruckType}`,
      // department: 'נהגי משאיות',
      shipper: this.share.company$.value.id,
      file: this.attachmentInput,
      lang: this.share.language$.value.id
    };
    httpObservable = this.api.updateSessionPriorityUser(this.share.courseId$.value, body);
    httpObservable.subscribe(event => {
      if (event.type === HttpEventType.UploadProgress) {
        // this.duringUpload = true;
        // // This is an upload progress event. Compute and show the % done:
        // const percentDone = Math.round(100 * event.loaded / event.total);
        // this.rotateDeg = 360 * (percentDone / 100.0);
      } else if (event instanceof HttpResponse) {
        // this.duringUpload = false;
        let res = event;
        if (res.status == 201){
  
        } else if (res.status == 200){
          let spaceIdx = this.share.employeeName$.value.indexOf(' ');
          let p1: string, p2: string = '';
          if (spaceIdx > 0){
            p1 = this.share.employeeName$.value.substring(0, spaceIdx);
            p2 = this.share.employeeName$.value.substring(spaceIdx+1);
          } else {
            p1 = this.share.employeeName$.value;
          }
          localStorage.setItem('employee-role', `נהג ${this.selectedTruckType}`);
          localStorage.setItem('employee-pname', p1);
          localStorage.setItem('employee-lname', p2);
          window.open(res.body, '_self');
          // window.open(res.headers.get('Location'),'_self');
        } else {
          console.log(res);
        }
      }
    }, err => {console.log(err)})
  }

  truckTitle(truck: Truck): string {
    if (this.share.language$.value.id === 'ar'){
      switch(truck){
        case Truck.Pallet: return 'شاحنة نقل مشاتيح / بلاطة';
        case Truck.Tanker: return 'صهريج';
        case Truck.Trailer: return 'قلاب /  قاطرة ومقطورة';
      }
    } else {
      return truck;
    }
  }
}
