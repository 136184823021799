import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { AccessComponent } from './access/access.component';
import { routing } from './course-access.routing';
import { FreeComponent } from './free/free.component';
import { CoursesComponent } from './courses/courses.component';
import { Free2Component } from './free2/free2.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    routing
  ],
  declarations: [AccessComponent, FreeComponent, CoursesComponent, Free2Component]
})
export class CourseAccessModule { }
