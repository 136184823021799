import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import { selectCourseList, selectCourseListExceptEveryone } from 'src/app/stores/course/course.selector';
import { ApiService } from '../../api-service/api.service';
import { AppState } from '../../stores/app/app.state';
import { SetList } from '../../stores/course/course.actions';
import { Course } from '../../stores/course/course.model';

@Injectable()
export class CourseService {

    selectedCourse: {course:Course, lang:string};
    constructor(private _store: Store<AppState>, private api: ApiService){}
    initStore(lite?:boolean){
        if (lite){
            this.api.getLiteCourseListFromBackend().subscribe(list => this._store.dispatch(new SetList(list)));
        } else {
            this.api.getCourseListFromBackend().subscribe(list => this._store.dispatch(new SetList(list)));
        }
    }
    clearStore(){
        this._store.dispatch(new SetList([]));
    }
    getCourseList(): Observable<Course[]> {
      return this._store.pipe(select(selectCourseListExceptEveryone));
    }
}