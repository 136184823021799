import { HistoryAction, HistoryActions } from './history.actions';
import { History } from './history.model';
import { HistoryState, initialHistoryState } from './history.state';

export const historyReducer = (
    state = initialHistoryState,
    action: HistoryActions
): HistoryState => {
    switch (action.type) {
        case HistoryAction.SetCourseHistory: {
            let employeeMap = {};
            for (let hist of action.payload.list){
                if (!employeeMap[hist.employee]){
                    employeeMap[hist.employee] = [];
                }
                employeeMap[hist.employee].push(hist);
            }
            return {
                ...state,
                course: {
                    subject: action.payload.course,
                    map: employeeMap
                }
            };
        }
        case HistoryAction.SetEmployeeHistory: {
            let courseMap = {};
            for (let hist of action.payload.list){
                if (!courseMap[hist.course]){
                    courseMap[hist.course] = [];
                }
                courseMap[hist.course].push(hist);
            }
            return {
                ...state,
                employee: {
                    subject: action.payload.employee,
                    map: courseMap
                }
            };
        }
        default: {
            return state;
        }
    }
}