import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, combineLatest, Observable, Subject } from 'rxjs';
import { filter } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class OATESmsService {
  
  readonly API_URL = 'https://panel.momenteken-college.co.il/oasm';
  subj: BehaviorSubject<{logoUrl: string, title: string}>;

  constructor(private http: HttpClient) { }

  getLogoAndTitle(c_id: any): Observable<{logoUrl: string, title: string}> {
    if (!this.subj){
      this.subj = new BehaviorSubject(null);
      // const WEBSITE_URL = this.API_URL;
      const WEBSITE_URL = `https://${window.location.hostname}/oasm`;
      combineLatest([
        this.http.get<{title: string}>(`${WEBSITE_URL}/${c_id}/title.json`, {observe: 'body', responseType: 'json'})
      ]).subscribe(([titleJson]) => {
        this.subj.next({logoUrl: `${WEBSITE_URL}/${c_id}/images/logo.png`, title: titleJson.title })
      }, console.log);
    }
    return this.subj.pipe(filter(x => !!x));
  }

  sendForSmsCode(courseId: number, name: string, idnum: string, phone: string){
    return this.http.post<{success: boolean}>(`${this.API_URL}/course/${courseId}/sms`, {name: name, idnum: idnum, phone: phone}, {withCredentials: true});
  }

  generateNewCode(courseId: number) {
    return this.http.get<{success: boolean}>(`${this.API_URL}/course/${courseId}/fresh`, {withCredentials: true});
  }

  verifySmsCode(courseId: number, code: string) {
    return this.http.post(`${this.API_URL}/${courseId}`, {code: code}, {withCredentials: true, responseType: 'text', observe: 'response'});
  }

  isSessionActive(courseId: number) {
    return this.http.get(`${this.API_URL}/course/${courseId}/check`, {withCredentials: true, responseType: 'text'});
  }
}