import { HttpEvent, HttpEventType, HttpResponse } from '@angular/common/http';
import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { ApiService } from 'src/app/api-service/api.service';
enum Truck {
  Pallet = 'נהג משאית פלטה',
  Tanker = 'נהג מיכלית',
  Trailer = 'נהג טריילר/אמבטיה'
}
@Component({
  selector: 'app-free2',
  templateUrl: './free2.component.html',
  styleUrls: ['./free2.component.scss']
})
export class Free2Component implements OnInit, OnDestroy {
  duringUpload:boolean = false;
  rotateDeg:number = 0;
  clientLogoUrl:string;
  inputFields:{text:string,type:string,input:string,key:string}[];
  invisibleFields:{text:string,type:string,input:string,key:string}[];
  attachment:{text:string,input:any,title:string};
  specialFields:{text:string,type:string,input:string,key:string,url?:string,val?:string,id:string}[];
  first:boolean = true;
  second:boolean = false;
  secondAndHalf: boolean = false;
  third:boolean = false;
  firstImgaeUrl:string;
  firstAudioUrl:string;
  secondAudioUrl:string;
  thirdAudioUrl:string;
  palletAudioUrl:string;
  trailerAudioUrl:string;
  tankerAudioUrl:string;
  companyId:string;
  courseId:number;
  isDemo:boolean;
  btnText:BehaviorSubject<string>;
  btnTextInt:number;
  selectedTruckType:Truck;
  optionsList:{[k:string]:Observable<any[]>} = {};
  private alreadyPlayed:boolean = false;
  private firstAudio:HTMLAudioElement;
  get Truck() {
    return Truck; 
  }

  @HostListener('window:mousedown', ['$event'])
  handleEvent(event: Event) {
    if (this.first){
      this.playAudio();
    }
  }

  @HostListener('window:touchstart', ['$event'])
  handleEvent2(event: Event) {
    this.handleEvent(event);
  }

  constructor(private api : ApiService, private activatedRoute: ActivatedRoute) { }

  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe(params => {
      this.companyId = params['company'];
      this.courseId = params['course'];
      this.clientLogoUrl = 'https://panel.momenteken-college.co.il/'+this.companyId+'/images/logo.png';
      this.firstImgaeUrl = 'https://panel.momenteken-college.co.il/'+this.companyId+'/images/intro'+this.courseId+'.jpg';
      this.firstAudioUrl = 'https://panel.momenteken-college.co.il/'+this.companyId+'/audio/'+this.courseId+'/audio.mp3';
      this.secondAudioUrl = 'https://panel.momenteken-college.co.il/'+this.companyId+'/audio/'+this.courseId+'/audio2.mp3';
      this.thirdAudioUrl = 'https://panel.momenteken-college.co.il/'+this.companyId+'/audio/'+this.courseId+'/audio3.mp3';
      this.palletAudioUrl = 'https://panel.momenteken-college.co.il/'+this.companyId+'/audio/'+this.courseId+'/audio-pallet.mp3';
      this.trailerAudioUrl = 'https://panel.momenteken-college.co.il/'+this.companyId+'/audio/'+this.courseId+'/audio-trailer.mp3';
      this.tankerAudioUrl = 'https://panel.momenteken-college.co.il/'+this.companyId+'/audio/'+this.courseId+'/audio-tanker.mp3';
      this.api.getJson(this.companyId,this.courseId).subscribe(res => {
        this.inputFields = res.body.fields.filter(f => !f.hidden);
        this.invisibleFields = res.body.fields.filter(f => f.hidden);
        if (res.body.attachment){
          this.attachment = res.body.attachment;
        }
        if (res.body.special_fields){
          this.specialFields = res.body.special_fields;
          for (let special of this.specialFields){
            if (special.type == 'url'){
              this.getUrlOptions({url:special.url, val: special.val, id: special.id});
            }
          }
        }
        if (res.body.demo){
          this.isDemo = true;
          this.btnText = new BehaviorSubject('דלג');
          this.btnTextInt = window.setInterval(() => {
            let empty = true;
            this.inputFields.forEach(f => {
              if (f.input && f.input.length > 0){
                empty = false;
              }
            });
            if (!empty){
              this.btnText.next('המשך');
            } else {
              this.btnText.next('דלג');
            }
          },500);
        }
      },
      err => {
        console.log(err);
      })
    });
  }

  playAudio(){
    if (!this.alreadyPlayed){
      this.alreadyPlayed = true;
      this.firstAudio = new Audio();
      this.firstAudio.src = this.firstAudioUrl;
      this.firstAudio.load();
      this.firstAudio.play();
    }    
  }

  setFirst(){
    if (this.firstAudio)
      this.firstAudio.pause();
    this.first = false;
    this.second = true;
  }

  setSecond(){
    if (this.isDemo){
      let toSend:any = {};
      this.inputFields.forEach(f => {
        toSend[f.key] = f.input;
      });
      this.invisibleFields.forEach(f => {
        toSend[f.key] = f.input;
      });
      this.api.updateSessionUser(toSend).subscribe(event => {
        if (event.type === HttpEventType.UploadProgress) {
          this.duringUpload = true;
          // This is an upload progress event. Compute and show the % done:
          const percentDone = Math.round(100 * event.loaded / event.total);
          this.rotateDeg = 360 * (percentDone / 100.0);
        } else if (event instanceof HttpResponse) {
          this.duringUpload = false;
          let res = event;
          if (res.status == 201){
    
          } else if (res.status == 200){
            window.open(res.body,'_self');
          } else {
            console.log(res);
          }
        }
      }, err => {console.log(err)});
      return;
    }
    let messages:string[] = [];
    for (let f of this.inputFields){
      if (!f.input){
        messages.push("יש למלא "+f.text);
      }
    }
    if (messages.length > 0){
      alert(messages.join('\n'));
      return;
    }
    if (this.specialFields){
      let field = this.inputFields.find(f => f.key == 'employee_id');
      if (ValidateID(field.input) < 0){
        alert('מספר תעודת זהות לא תקין.\nיש להזין 9 ספרות, כולל ספרת ביקורת');
        return;
      } else {
        this.second = false;
        this.secondAndHalf = true;
      }
    } else {
      this.second = false;
      this.third = true;
    }
  }

  setThird(){
    let messages:string[] = [];
    for (let f of this.specialFields){
      if (!f.input){
        if (f.type == 'date'){
          messages.push("יש למלא "+f.text);
        } else {
          messages.push("יש לבחור "+f.text);
        }
      }
    }
    if (messages.length > 0){
      alert(messages.join('\n'));
      return;
    }
    this.secondAndHalf = false;
    this.third = true;
  }

  getUrlOptions(field:{url:string, val:string, id:string}){
    if (!this.optionsList[field.url]){
      let subj = new BehaviorSubject<any[]>([]);
      this.optionsList[field.url] = subj;
      this.api.getOptionsFromUrl(field.url).pipe(
        map(list => {
          return list.map(opt => {
            return {
              text: opt[field.val] ? opt[field.val] : '',
              value: opt[field.id]
            }
          }).sort((a, b) => a.text.localeCompare(b.text));
        }),
      ).subscribe(list => {
        subj.next(list);
      });
    }
    return this.optionsList[field.url];
  }

  startSession(){
    let toSend:any = {};
    let messages:string[] = [];
    this.inputFields.forEach(f => {
      toSend[f.key] = f.input;
    });
    this.invisibleFields.forEach(f => {
      toSend[f.key] = f.input;
    });
    this.specialFields.forEach(f => {
      toSend[f.key] = f.input;
    });
    if (!this.selectedTruckType){
      messages.push("יש לבחור סוג משאית");
    } else {
      let role = this.selectedTruckType;
      toSend['role'] = role;
    }
    if (this.attachment && this.selectedTruckType === Truck.Tanker){
      if (this.attachment.input == null){
        messages.push(this.attachment.text);
      }
      toSend.attachment = this.attachment.input;
      toSend.attachment_title = this.attachment.title;
    }
    if (messages.length > 0){
      alert(messages.join('\n'));
      return;
    }
    localStorage.setItem('employee-role',this.selectedTruckType);
    localStorage.setItem('employee-pname',toSend['pname']);
    localStorage.setItem('employee-lname',toSend['lname']);
    
    let httpObservable: Observable<HttpEvent<string>>;
    if (this.specialFields){
      httpObservable = this.api.updateSessionPriorityUser(toSend);
    } else {
      httpObservable = this.api.updateSessionUser(toSend);
    }
    httpObservable.subscribe(event => {
      if (event.type === HttpEventType.UploadProgress) {
        this.duringUpload = true;
        // This is an upload progress event. Compute and show the % done:
        const percentDone = Math.round(100 * event.loaded / event.total);
        this.rotateDeg = 360 * (percentDone / 100.0);
      } else if (event instanceof HttpResponse) {
        this.duringUpload = false;
        let res = event;
        if (res.status == 201){
  
        } else if (res.status == 200){
          window.open(res.body,'_self');
          // window.open(res.headers.get('Location'),'_self');
        } else {
          console.log(res);
        }
      }
    }, err => {console.log(err)})
  }

  doNothing(){}

  uploadEducationImage(event:any){
    if (this.attachment){
      let reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]);
      reader.addEventListener('load', (event1: any) => {
        const result = event1.target.result;
        const sixMBbytes = 6291456;
        const base64size = sixMBbytes * 1.3333333; // base 64 encode has extra byte for every 3 byte
        if (result.length  > base64size){
          alert('גודל קובץ מקסימלי: 6MB')
        } else {
          this.attachment.input = result;
        }
      });
    }
  }

  getDemoText():Observable<string>{
    return this.btnText
  }

  ngOnDestroy(): void {
    if (this.btnTextInt){
      clearInterval(this.btnTextInt);
    }
  }
}

function ValidateID(str){
  let IDnum = String(str);

  // Validate correct input
  if ((IDnum.length > 9))
    return -1;

  if (Number.isNaN(IDnum))
    return -2;

  // The number is too short - add leading 0000
  if (IDnum.length < 9){
    IDnum = IDnum.padStart(9, '0');
  }

  // CHECK THE ID NUMBER
  let mone = 0, incNum;
  for (let i = 0; i < 9; i++){
    incNum = Number(IDnum.charAt(i));
    incNum *= (i%2)+1;
    if (incNum > 9)
      incNum -= 9;
    mone += incNum;
  }
  if (mone%10 == 0)
    return 1;
  else
    return -3;
}