import { Component, HostListener, OnInit, ElementRef, ViewChild, ViewChildren, QueryList, AfterViewInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { last, map, switchMap, take } from 'rxjs/operators';
import { Assign } from 'src/app/stores/assign/assign.model';
import { Course } from 'src/app/stores/course/course.model';
import { Employee } from 'src/app/stores/employee/employee.model';
import { History } from 'src/app/stores/history/history.model';
import { AssignService } from '../services/assign.service';
import { getLangHebrewTitle } from '../../api-service/api.service';
import { EmployeeService } from '../services/employee.service';
import { HistoryService } from '../services/history.service';

const fields = {
  number:97,
  firstName:199,
  surname:322,
  identity:442,
  site:517,
  department:627,
  role:714
}

@Component({
  selector: 'app-employee-list',
  templateUrl: './employee-list.component.html',
  styleUrls: ['./employee-list.component.scss']
})
export class EmployeeListComponent implements OnInit, AfterViewInit {

  higher:boolean;
  wider:boolean;
  preventSequenceOpening:boolean;
  obj:any;
  arrowOffset:any;
  @HostListener('document:mouseup', ['$event.target'])
  onClick(target) {
    if (this.filterElement){
      const clickedInside = this.filterElement.nativeElement.contains(target);
      if (!clickedInside) {
        this.closeFilter(false);
        this.preventSequenceOpening = true;
        window.setInterval(()=>{this.preventSequenceOpening = false;},100);
      }
    }
  }
  @ViewChild('filter') filterElement:ElementRef;
  @ViewChildren('row') rowsElements:QueryList<ElementRef>;
  @ViewChild('scrollable') scrollElement:ElementRef;
  constructor(
    private employeeService: EmployeeService,
    private assignService: AssignService,
    private historyService: HistoryService
  ) { }

  ngOnInit(): void {
    this.obj = this.employeeService.initialFilterList(fields);
    if (history.state.employee_id > 0){
      let emp = this.obj.allEmployees.find(emp => emp.id == history.state.employee_id);
      this.selectEmployee(emp);
    } else {
      this.employeeService.selectedEmployee = null;
    }
  }

  ngAfterViewInit(): void {
    this.scrollElement.nativeElement.onscroll = () => this.calculateArrowPos();
    window.setTimeout(() => {
      let row = this.rowsElements.find(el => {
        let x = el.nativeElement.getAttribute('selected');
        return x === 'true'
      });
      if (row){
        this.scrollToElement(row.nativeElement);
      }
    },50);
  }

  openFilter(key:string){
    if (this.preventSequenceOpening){
      return;
    }
    window.setTimeout(() => {this.higher = true}, 10)
    window.setTimeout(() => {this.wider = true}, 160)
    this.employeeService.openFilter(key);
  }

  closeFilter(bool:boolean){
    if (bool){
      this.selectEmployee(null);
    }
    let onlyOneEmployeeFilter = this.employeeService.closeFilter(bool);
    if (onlyOneEmployeeFilter){
      this.selectEmployee(onlyOneEmployeeFilter);
    }
    this.higher = undefined;
    this.wider = undefined;
  }

  checkAllFilter(){
    this.employeeService.checkAllFilter();
  }

  doCheck(op: {value:string,check:boolean}){
    this.employeeService.doCheck(op);
  }

  isAllFilterChecked(){
    return this.employeeService.isAllFilterChecked();
  }

  onFilterSearchChange(event){
    if (event.keyCode == 13){//'Enter' event
      this.closeFilter(true);
      return;
    }
    if (!this.obj.filterSearchPrevValue){
      this.employeeService.checkAllFilter('checked');
    } else if (!this.obj.filterSearchValue){
      this.employeeService.checkAllFilter('baboom');
    }
    this.employeeService.applySearchFilter();
    this.obj.filterSearchPrevValue = this.obj.filterSearchValue;
  }

  selectEmployee(emp:Employee){
    this.employeeService.selectedEmployee = emp;
    this.assignService.setEmployee(emp);
    this.historyService.setEmployee(emp);
    window.setTimeout(() => {
      this.calculateArrowPos();
    },100);
  }

  calculateArrowPos(){
    let row = this.rowsElements.find(el => {
      let x = el.nativeElement.getAttribute('selected');
      return x === 'true'
    });
    if (row){
      this.arrowOffset = row.nativeElement.offsetTop - 6 - this.scrollElement.nativeElement.scrollTop;
      if (this.arrowOffset < 115 || this.arrowOffset > 584){
        this.arrowOffset = - 2000;
      }
    }
  }

  scrollToElement($element:HTMLElement): void {
    setTimeout(() => $element.scrollIntoView({behavior: "smooth", block: "start", inline: "nearest"}),100);
  }

  isSelected(emp:Employee){
    return this.employeeService.selectedEmployee === emp
  }

  getSelected(){
    return this.employeeService.selectedEmployee
  }

  importSubmit(event){
    let reader = new FileReader();
    reader.readAsDataURL(event.target.files[0]);
    event.target.checked = false;
    reader.addEventListener('load', (event1: any) => {
      let fileToUpload = event1.target.result;
      this.employeeService.insertMultiEmployees(fileToUpload);
      // .subscribe(res => {
      //   if (res.status == 202){
      //     this.ngOnInit();
      //   }
      // },err => console.log(err));
    });
  }

  exportList(){
    this.employeeService.getEmployeesXlsx();
  }

  getAssigns(){
    return this.assignService.getEmployeeAssign();
  }

  isAssignsResolve(){
    return this.assignService.isEmployeeAssignResolved();
  }

  removeAssign(assign:Assign){
    this.assignService.removeEmployeeAssign(this.employeeService.selectedEmployee.id,assign);
  }

  getHistory(){
    return this.historyService.getEmployeeHistory();
  }

  isHistoryResolve(){
    return this.historyService.isEmployeeHistoryResolved();
  }

  setSelectedHistory(history:{course: Course, list: History[]}):void{
    this.historyService.selectedHistory = history;
  }

  getSelectedHistory():{course: Course, list: History[]}{
    return this.historyService.selectedHistory;
  }

  printCertificate() {
    this.historyService.isCertificateHasTwoPages().subscribe(has => {
      if (has){
        let popupWin = window.open('','_blank','width=1175,height=863,menubar=no');
        popupWin.document.write(this.historyService.getHtmlCertificate(this.employeeService.selectedEmployee, true));
        popupWin.onload = () => popupWin.print();
        // popupWin.onafterprint = () => popupWin.close();
        popupWin.document.close();// seem that print() doesn't work without that line
      } else {
        let popupWin = window.open('','_blank','width=1175,height=863,menubar=no');
        popupWin.document.write(this.historyService.getHtmlCertificate(this.employeeService.selectedEmployee, false));
        popupWin.onload = () => popupWin.print();
        // popupWin.onafterprint = () => popupWin.close();
        popupWin.document.close();// seem that print() doesn't work without that line
      }
    });
  }

  getLangHebrewTitle = getLangHebrewTitle;
}
