<div *ngIf="first" class="container1" [ngStyle]="{'background-image': 'url(' + firstImgaeUrl + ')'}">
    <!-- <audio *ngIf="firstAudioUrl" controls autoplay style="display:none;">
        <source [src]="firstAudioUrl" type="audio/mpeg">
    </audio> -->
    <div class="to-fill-width" style="margin-top: 631px;">
        <div class="button1" (click)="setFirst()">המשך</div>
    </div>
</div>
<div *ngIf="second" class="container">
    <audio *ngIf="secondAudioUrl" controls autoplay style="display:none;">
        <source [src]="secondAudioUrl" type="audio/mpeg">
    </audio>
    <img class="logo" [src]="clientLogoUrl">
    <!-- src="/assets/temporary.png" -->
    <div class="text-fill-details">מלא את הפרטים הבאים</div>
    <div class="fields-container">
        <div class="to-fill-width" *ngFor="let field of inputFields">
            <input [type]="field.type" [placeholder]="field.text" [(ngModel)]="field.input">
        </div>
    </div>
    <div class="to-fill-width">
        <!-- style="height: 50px;"-->
        <!-- move to third -->
    </div>
    <div class="to-fill-width">
        <div *ngIf="isDemo" class="button" (click)="setSecond()">{{getDemoText() | async}}</div>
        <div *ngIf="!isDemo" class="button" (click)="setSecond()">המשך</div>
    </div>
</div>
<div *ngIf="secondAndHalf" class="container">
    <audio *ngIf="false" controls autoplay style="display:none;">
        <source [src]="null" type="audio/mpeg">
    </audio>
    <div class="fields-container" style="padding-top: 30px;">
        <div class="to-fill-width" *ngFor="let field of specialFields">
            <div style="font-size: 25px; text-align: right; width: 290px; margin: auto;">{{field.text}}</div>
            <input *ngIf="field.type == 'date'" [type]="field.type" [(ngModel)]="field.input">
            <select *ngIf="field.type == 'url'" (change)="field.input = $event.target.value">
                <option value="" disabled selected>בחר {{field.text}}</option>
                <option [value]="opt.value" *ngFor="let opt of getUrlOptions(field) | async">{{opt.text}}</option>
            </select>
        </div>
    </div>
    <div class="to-fill-width">
        <!-- style="height: 50px;"-->
        <!-- move to third -->
    </div>
    <div class="to-fill-width">
        <div class="button" (click)="setThird()">המשך</div>
    </div>
</div>
<div *ngIf="third" class="container">

    <audio *ngIf="thirdAudioUrl" controls autoplay style="display:none;">
        <source [src]="thirdAudioUrl" type="audio/mpeg">
    </audio>

    <img class="logo" style="margin-bottom: 10px;" [src]="clientLogoUrl">

    <div style="text-align: center; color: #1E3E93; font-size: 38px; font-weight: 400;direction: rtl; padding: 15px 0;">באיזה סוג משאית אתה נוהג?</div>

    <div class="truck-btn" (click)="selectedTruckType = Truck.Pallet" [ngClass]="{'clicked': selectedTruckType === Truck.Pallet }">
        <audio *ngIf="selectedTruckType === Truck.Pallet" controls autoplay style="display:none;">
            <source [src]="palletAudioUrl" type="audio/mpeg">
        </audio>
        <div class="img-wrapper">
            <img src="/assets/pallet-truck.png">
        </div>
        <div class="title">משאית פלטה</div>
    </div>
    <div class="truck-btn" (click)="selectedTruckType = Truck.Tanker" [ngClass]="{'clicked': selectedTruckType === Truck.Tanker }">
        <audio *ngIf="selectedTruckType === Truck.Tanker" controls autoplay style="display:none;">
            <source [src]="tankerAudioUrl" type="audio/mpeg">
        </audio>
        <div class="img-wrapper">
            <img src="/assets/tanker.png">
        </div>
        <div class="title">מיכלית</div>
    </div>
    <div class="truck-btn" (click)="selectedTruckType = Truck.Trailer" [ngClass]="{'clicked': selectedTruckType === Truck.Trailer }">
        <audio *ngIf="selectedTruckType === Truck.Trailer" controls autoplay style="display:none;">
            <source [src]="trailerAudioUrl" type="audio/mpeg">
        </audio>
        <div class="img-wrapper">
            <img src="/assets/trailer.png">
        </div>
        <div class="title">טריילר / אמבטיה</div>
    </div>

    <div class="to-fill-width" style="height: 70px;" *ngIf="selectedTruckType == Truck.Tanker">
        <div class="attachment">
            <div class="attach-text">{{attachment.text}}</div>
            <div class="attach-btns-wrapper">
                <div class="attach-icon-wrapper" (click)="fileInput1.click()">
                    <img class="attach-icon" src="/assets/camera.png">
                    <input style="display: none" type="file" accept="image/*" capture="camera" #fileInput1 (change)="uploadEducationImage($event)">
                </div>
                <div class="attach-icon-wrapper" (click)="fileInput2.click()">
                    <img class="attach-icon" src="/assets/clip2.png">
                    <input style="display: none" type="file" accept="image/*" #fileInput2 (change)="uploadEducationImage($event)">
                    <!--,application/pdf-->
                </div>
            </div>
        </div>
    </div>
    <div class="to-fill-width">
        <div class="button3" (click)="startSession()">המשך</div>
    </div>
</div>
<div *ngIf="duringUpload" class="above">
    <img class="logo" [src]="clientLogoUrl" [ngStyle]="{'transform' : 'rotate('+ rotateDeg +'deg)'}">
</div>