import { Action } from '@ngrx/store';
import { Course } from '../course/course.model';
import { Employee } from '../employee/employee.model';
import { Assign } from './assign.model';

export enum AssignAction {
    EditCourseAssigns = '[Assign] create and remove multi assigns for course',
    RemoveEmployeeAssign = '[Assign] remove existing assign of employee',
    SendCourseAssigns = '[Assign] send multi assigns for course',
    SetCourseAssign = '[Assign] loads course assign list',
    SetEmployeeAssign = '[Assign] loads employee assign list'
}

export class EditCourseAssigns implements Action {
    public readonly type = AssignAction.EditCourseAssigns;
    constructor(public payload: Assign[]) {}
}
export class RemoveEmployeeAssign implements Action {
    public readonly type = AssignAction.RemoveEmployeeAssign;
    constructor(public payload: number) {}
}
export class SendCourseAssigns implements Action {
    public readonly type = AssignAction.SendCourseAssigns;
    constructor(public payload: Course) {}
}
export class SetCourseAssign implements Action {
    public readonly type = AssignAction.SetCourseAssign;
    constructor(public payload: {course: Course, list: Assign[]}) {}
}
export class SetEmployeeAssign implements Action {
    public readonly type = AssignAction.SetEmployeeAssign;
    constructor(public payload:  {employee: Employee, list: Assign[]}) {}
}
export type AssignActions = EditCourseAssigns | RemoveEmployeeAssign | SendCourseAssigns | SetCourseAssign | SetEmployeeAssign;