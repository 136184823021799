<div class="flexy-part intro-id">
    {{!companies ? enterIdnumText : companyText}}
</div>

<div *ngIf="!companies" class="iden-input-wrapper">
    <div class="inputs-wrapper" [class.error]="!!error" [style.background]="disableInput ? 'lightgrey' : undefined">
        <div class="gap" (click)="firstInput.focus()" [ngStyle]="{display: 'block'}"></div>
        <input style="margin-right: 0;" #firstInput id="digit-0" [type]="isMobile ? 'number' : 'text'" [value]="digitInputValues[0]" (keydown)="digitKeydown(0, $event)" (keyup)="digitKeyup(0, $event)" (focus)="digitFocus($event)" autocomplete="off" (blur)="digitBlur($event)" [disabled]="disableInput">
        <div class="gap" style="width: 6px;" (click)="firstInput.focus()" [ngStyle]="{flex: '1'}"></div>
        <input style="margin-left: 0;" #secondInput id="digit-1" [type]="isMobile ? 'number' : 'text'" [value]="digitInputValues[1]" (keydown)="digitKeydown(1, $event)" (keyup)="digitKeyup(1, $event)" (focus)="digitFocus($event)" autocomplete="off" (blur)="digitBlur($event)" [disabled]="disableInput">
        <div class="gap" style="width: 6px;" (click)="secondInput.focus()" [ngStyle]="{flex: '1'}"></div>
        <input style="margin-right: 0;" #thirdInput id="digit-2" [type]="isMobile ? 'number' : 'text'" [value]="digitInputValues[2]" (keydown)="digitKeydown(2, $event)" (keyup)="digitKeyup(2, $event)" (focus)="digitFocus($event)" autocomplete="off" (blur)="digitBlur($event)" [disabled]="disableInput">
        <div class="gap" style="width: 6px;" (click)="thirdInput.focus()" [ngStyle]="{flex: '1'}"></div>
        <input style="margin-right: 0;" #fourthInput id="digit-3" [type]="isMobile ? 'number' : 'text'" [value]="digitInputValues[3]" (keydown)="digitKeydown(3, $event)" (keyup)="digitKeyup(3, $event)" (focus)="digitFocus($event)" autocomplete="off" (blur)="digitBlur($event)" [disabled]="disableInput">
        <div class="gap" style="width: 6px;" (click)="fourthInput.focus()" [ngStyle]="{flex: '1'}"></div>
        <input style="margin-right: 0;" #fifthInput id="digit-4" [type]="isMobile ? 'number' : 'text'" [value]="digitInputValues[4]" (keydown)="digitKeydown(4, $event)" (keyup)="digitKeyup(4, $event)" (focus)="digitFocus($event)" autocomplete="off" (blur)="digitBlur($event)" [disabled]="disableInput">
        <div class="gap" style="width: 6px;" (click)="fifthInput.focus()" [ngStyle]="{flex: '1'}"></div>
        <input style="margin-right: 0;" #sixthInput id="digit-5" [type]="isMobile ? 'number' : 'text'" [value]="digitInputValues[5]" (keydown)="digitKeydown(5, $event)" (keyup)="digitKeyup(5, $event)" (focus)="digitFocus($event)" autocomplete="off" (blur)="digitBlur($event)" [disabled]="disableInput">
        <div class="gap" style="width: 6px;" (click)="sixthInput.focus()" [ngStyle]="{flex: '1'}"></div>
        <input style="margin-right: 0;" #seventhInput id="digit-6" [type]="isMobile ? 'number' : 'text'" [value]="digitInputValues[6]" (keydown)="digitKeydown(6, $event)" (keyup)="digitKeyup(6, $event)" (focus)="digitFocus($event)" autocomplete="off" (blur)="digitBlur($event)" [disabled]="disableInput">
        <div class="gap" style="width: 6px;" (click)="seventhInput.focus()" [ngStyle]="{flex: '1'}"></div>
        <input style="margin-right: 0;" #eighthInput id="digit-7" [type]="isMobile ? 'number' : 'text'" [value]="digitInputValues[7]" (keydown)="digitKeydown(7, $event)" (keyup)="digitKeyup(7, $event)" (focus)="digitFocus($event)" autocomplete="off" (blur)="digitBlur($event)" [disabled]="disableInput">
        <div class="gap" style="width: 6px;" (click)="eighthInput.focus()" [ngStyle]="{flex: '1'}"></div>
        <input style="margin-left: 0;" #lastInput id="digit-8" [type]="isMobile ? 'number' : 'text'" [value]="digitInputValues[8]" (keydown)="digitKeydown(8, $event)" (keyup)="digitKeyup(8, $event)" (focus)="digitFocus($event)" autocomplete="off" (blur)="digitBlur($event)" [disabled]="disableInput">
        <div class="gap" (click)="lastInput.focus()" [ngStyle]="{display: 'block'}"></div>
    </div>
</div>

<div *ngIf="!companies" class="flexy-part" style="min-height: 60px;">
    <div class="err-msg" *ngIf="error">{{errorMessage(error)}}</div>
    <div class="err-msg2" *ngIf="error && error === InputError.NOT_FOUND">{{goToStation}}</div>
</div>

<div *ngIf="!companies && error !== InputError.NOT_FOUND" class="flexy-part" style="margin-top: 50px;">
    <div class="next-btn" (click)="btnClick()">{{nextBtnText}}</div>
</div>

<div *ngIf="companies" class="select-company-wrapper">
    <select (change)="companySelect({id:$event.target.value})" class="select-company">
        <option value="" disabled selected>{{selectCompanyText}}</option>
        <option [value]="comp.id" *ngFor="let comp of companies">{{comp.name}}</option>
    </select>
</div>