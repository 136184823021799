<div class="access-content">

    <div class="flexy-part">
        <div class="prompt">הקש מספר זיהוי</div>
        <div class="ids-btns">
            <label (click)="idClick()" id="id-click">
                <input type="radio"
                    [checked]="idRatherThanPassport ? 'checked' : undefined" />
                <span class="checkmark"></span>
                <span>תעודת זהות</span>
            </label>
            <label (click)="passportClick()" id="passport-click">
                <input type="radio"
                    [checked]="!idRatherThanPassport ? 'checked' : undefined" />
                <span class="checkmark"></span>
                <span>דרכון/Passport</span>
            </label>
        </div>
        <div class="box-for-input">
            <input *ngIf="idRatherThanPassport" id="partitioned" type="number" maxlength="9" (keyup)="inputKeyup($event)" (keydown)="inputKeydown($event)">
            <input *ngIf="!idRatherThanPassport" id="partitioned2" [(ngModel)]="inputValue">
        </div>
    </div>

    <div class="flexy-part">
        <div class="err-msg">{{error}}</div>
        <div class="next-btn" (click)="toDoClick()">המשך</div>
    </div>


</div>