import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { SelectLanguagesComponent } from './select-languages/select-languages.component';
import { RouterModule } from '@angular/router';
import { LanguagesResolver } from './languages.resolver';
import { ApiAccessService } from './api-access.service';
import { InsertIdnumComponent } from './insert-idnum/insert-idnum.component';
import { SelectTruckComponent } from './select-truck/select-truck.component';

@NgModule({
  declarations: [SelectLanguagesComponent, InsertIdnumComponent, SelectTruckComponent],
  imports: [
    CommonModule,
    FormsModule,
    RouterModule.forChild([
      { path: ':c_id', component: SelectLanguagesComponent, pathMatch: 'full' },
      { path: ':c_id/:lang', component: InsertIdnumComponent, resolve: {resolved: LanguagesResolver}, pathMatch: 'full' },
      { path: ':c_id/:lang/routes', component: SelectTruckComponent, resolve: {resolved: LanguagesResolver} }
    ])
  ]
})
export class OpenAccessModule { }
