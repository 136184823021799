<div class="upper"></div>
<div class="logo-wrapper">
    <img [src]="logoUrl">
</div>
<div class="title-wrapper">
    <span>{{courseTitle}}</span>
</div>
<div class="intro-title">יש למלא את הפרטים הבאים:</div>
<form action="submit">
    <div class="input-label-wrapper" [class.error]="!name && submitted && !hideRed">
        <input autocomplete="off" type="text" name="name" [(ngModel)]="name" placeholder="שומר מקום">
        <label for="name">
            <span class="asterisk">*</span>
            <span>שם מלא (פרטי + משפחה)</span>
        </label>
        <ng-container *ngTemplateOutlet="screamerTemplate;context:{message:'חובה למלא'}"></ng-container>
    </div>
    <div class="input-label-wrapper" [class.error]="!idnum && submitted && !hideRed">
        <input autocomplete="off" type="text" name="idnum" [(ngModel)]="idnum" placeholder="שומר מקום">
        <label for="idnum">
            <span class="asterisk">*</span>
            <span>תעודת זהות</span>
        </label>
        <ng-container *ngTemplateOutlet="screamerTemplate;context:{message:'חובה למלא'}"></ng-container>
    </div>
    <div class="input-label-wrapper" [class.error]="!!phoneError() && !hideRed">
        <input autocomplete="off" type="tel" name="phone" [(ngModel)]="phone" placeholder="שומר מקום">
        <label for="phone">
            <span class="asterisk">*</span>
            <span>נייד</span>
        </label>
        <ng-container *ngTemplateOutlet="screamerTemplate;context:{message: phoneError() }"></ng-container>
    </div>
    <button #submitButton style="display: none;" type="submit" (click)="nextClick()"></button>
</form>
<div class="gap"></div>
<div class="footy" (click)="submitButton.click()">המשך</div>
<ng-template #screamerTemplate let-message="message">
    <div class="new-screamer">
        <img class="screamer-img" src="assets/screamer.svg">
        <span class="screamer-txt">{{message}}</span>
    </div>
</ng-template>