import { HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { first, take } from 'rxjs/operators';
import { ApiService } from 'src/app/api-service/api.service';
import { AppState } from 'src/app/stores/app/app.state';
import { SetEmployeeAssign, SetCourseAssign, RemoveEmployeeAssign } from 'src/app/stores/assign/assign.actions';
import { Assign } from 'src/app/stores/assign/assign.model';
import { combineAssignWithHistory, selectCourseNoSentAssign, selectEmployeeAssign, selectEmployeeOfAssign } from 'src/app/stores/assign/assign.selector';
import { Course } from 'src/app/stores/course/course.model';
import { Employee, EmployeePlus } from 'src/app/stores/employee/employee.model';
import { CourseService } from './couse.service';
import * as cloneDeep from 'lodash/cloneDeep';

const clone:<T>(obj:T) => T = cloneDeep;

@Injectable()
export class AssignService {

  editingList$:BehaviorSubject<Assign[]> = new BehaviorSubject([]);
  dirtyListHolder:{dirty:Assign[]} = {dirty:[]};
  constructor(private _store: Store<AppState>, private api: ApiService, private courseService: CourseService){}
  clearStore(): void{
      this._store.dispatch(new SetEmployeeAssign({employee: null, list: []}));
      this._store.dispatch(new SetCourseAssign({course: null, list: []}));
  }
  setEmployee(emp:Employee){
      this._store.dispatch(new SetEmployeeAssign({employee: null, list: []}))
      if (emp)
        this.api.getEmployeeAssignFromBackend(emp.id).subscribe(list => this._store.dispatch(new SetEmployeeAssign({employee: emp, list: list})));
  }
  setCourse(course:Course){
      this._store.dispatch(new SetCourseAssign({course: null, list: []}));
      this.clearChanges();
      this.api.getCourseAssignFromBackend(course.id).subscribe(list => {
        this._store.dispatch(new SetCourseAssign({course: course, list: list}));
        this.getCourseAssign().pipe(first()).subscribe(list => {
          // the different b/w the above backend-list and this one, is that this has additional info such employee name, and it is filtered by 'sent' date
          this.editingList$.next(list);
          this.dirtyListHolder.dirty = list.map(ass => clone(ass));  
        });
      });
  }
  getEmployeeAssign(): Observable<Assign[]>{
      return this._store.pipe(select(selectEmployeeAssign));
  }
  getCourseAssign(): Observable<Assign[]> {
    return this._store.pipe(select(selectCourseNoSentAssign));
  }
  isEmployeeAssignResolved(): Observable<Employee>{
      return this._store.pipe(select(selectEmployeeOfAssign));
  }
  removeEmployeeAssign(emp_id:number,assign: Assign) {
      return new Promise<boolean>((resolve,reject) => {    
          this.api.removeAssignOfEmployee(emp_id,assign.id).subscribe(
            status => {
              if (status == 200){
                this._store.dispatch(new RemoveEmployeeAssign(assign.id));
                resolve(true);
              } else {
                reject({error:'Unexpected code: '+status});
              }
            }, reject
          );
      });
  }
  getEmployees(): Observable<EmployeePlus[]>{
    return this._store.pipe(select(combineAssignWithHistory));
  }
  saveDirty(send:boolean = false):Promise<void>{
    return new Promise((resolve,reject) => {
      let arrToDel:number[] = [];
      for (let ass of this.editingList$.value){
        if (this.dirtyListHolder.dirty.findIndex(a => a.employee == ass.employee) < 0){
          arrToDel.push(ass.employee);
        }
      }
      let arrToInsert:number[] = [];
      for (let assign of this.dirtyListHolder.dirty){
        if (this.editingList$.value.findIndex(ass => ass.employee == assign.employee) < 0){
          arrToInsert.push(assign.employee);
        }
      }
      let handle = (resStatus:number) => {
        if (resStatus == 200){
          this.clearChanges();
          this.setCourse(this.courseService.selectedCourse.course);
          resolve();
        } else {
          reject({error:'Unexpected code: '+resStatus});
        }
      }
      this.api.saveAssignmentsToBackend(this.courseService.selectedCourse.course.id, {delete: arrToDel, insert: arrToInsert, lang: this.courseService.selectedCourse.lang, send: send ? 'true' : undefined }).subscribe(handle, reject);
    });
  }
  isDirtyChanges():boolean{
    if (!this.editingList$.value){
      return false;      
    }
    for (let ass of this.editingList$.value){
      if (this.dirtyListHolder.dirty.findIndex(a => a.employee == ass.employee) < 0){
        return true;
      }
    }
    for (let assign of this.dirtyListHolder.dirty){
      if (this.editingList$.value.findIndex(ass => ass.employee == assign.employee) < 0){
        return true;
      }
    }
    return false;
  }
  clearChanges(){
    this.editingList$.next([]);
    this.dirtyListHolder.dirty = [];
  }
}