import { createSelector } from '@ngrx/store';
import { AppState } from '../app/app.state';
import { CourseState } from './course.state';

const selectCourses = (state:AppState) => state.course;

export const selectCourseList = createSelector(
    selectCourses,
    (state:CourseState) => state.list
);
export const selectCourseListExceptEveryone = createSelector(
    selectCourses,
    (state:CourseState) => state.list.filter(c => !c.everyone)
);