import { ModuleWithProviders } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AccessComponent } from './access/access.component';
import { CoursesComponent } from './courses/courses.component';
import { FreeComponent } from './free/free.component';
import { LoggedInGuard } from '../logged-in.guard';
import { Free2Component } from './free2/free2.component';

export const routes: Routes = [
  { path: '', component: Free2Component },
  // { path: 'oate/:c_id', component: SelectLanguagesComponent, resolve: LanguagesResolver },
  { path: 'courses', component: CoursesComponent, canActivate: [LoggedInGuard]},
  { path: 'courses/:c_id/:lang?', canActivate: [LoggedInGuard], component: AccessComponent },
  { path: ':c_id/:token', component: AccessComponent }
];

export const routing: ModuleWithProviders<RouterModule> = RouterModule.forChild(routes);