import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { filter, map } from 'rxjs/operators';
import { ApiAccessService } from '../api-access.service';
import { shareService } from '../language.service';

@Component({
  selector: 'app-select-languages',
  templateUrl: './select-languages.component.html',
  styleUrls: ['./select-languages.component.scss']
})
export class SelectLanguagesComponent implements OnInit {

  WEBSITE_URL;
  languages: {id: string, name: string, name_h: string}[] = [];
  clientLogoUrl: string;

  constructor(private route: ActivatedRoute, private api: ApiAccessService, private languageShare: shareService, private router: Router) {
    // const domain = window.location.hostname;
    // if (domain === 'localhost' || domain === '127.0.0.1' || domain === '192.168.200.10'){
    //   this.WEBSITE_URL = api.API_URL;
    // } else {
      this.WEBSITE_URL = `https://${window.location.hostname}/oate`;
    // }
  }

  ngOnInit(): void {
    this.route.params.pipe(map(params => params.c_id), filter(c_id => !isNaN(+c_id))).subscribe(c_id => {
      this.api.getCourseLanguages(+c_id).subscribe(res => {
        res.find(l => l.id === 'ar').name = 'عربي';
        this.languages = [...res];
        this.clientLogoUrl = `${this.WEBSITE_URL}/${c_id}/images/logo.png`;
      });
    });
  }

  selectLanguage(lang: {id: string, name: string, name_h: string}){
    this.languageShare.language$.next(lang);
    this.router.navigate([lang.id], {relativeTo: this.route});
  }
}
