declare const Pusher: any;
import { Injectable, APP_ID } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
// import { BASE_URL } from './client.service';

@Injectable()
export class PusherService {
    pusher: any;
    channels: {name:string, value:any, time:number, event:string|number}[];
    constructor() {
        this.pusher = new Pusher('5fcdd4eac3f5cc7552a0', {
            cluster: 'eu',
            encrypted: true,
            disableStats: true
        });
        this.channels = [];
    }
    addChannel(channelName:string,event:string|number){
        let found = this.channels.find(channel => channel.name == channelName);
        if (found) {
            found.value.unbind(found.event);
        } else {
            let channel = this.pusher.subscribe(channelName);
            this.channels.push({name:channelName,value:channel,time:0,event:event});
        }
    }
    registerChangeDetecter(channel : string, eventValue : string | number):Observable<any>{
        let subject:BehaviorSubject<number> = new BehaviorSubject(Date.now());
        this.addChannel(channel,eventValue);
        this.channels.find(chan => chan.name == channel).value.bind(eventValue, data => {
            console.log("notify data just changed; channel: "+channel+", event: "+eventValue);
            subject.next(Date.now());
        });
        return subject.pipe(map(time => {
            let obj = this.channels.find(c => c.name == channel);
            if (time - obj.time > 5000) {
                obj.time = new Date().getTime();
                return time;
            } else {
                console.log("skip "+channel+". too soon...");
            }
        }));;
    }
    // unregisterChangeDetecter(channel : string, eventValue : string | number){
    //     let chan = this.channels.find(chan => chan.name == channel);
    //     if (chan) chan.value.unbind(eventValue);
    // }
}