<div class="upper"></div>
<div class="logo-wrapper">
    <img [src]="logoUrl">
</div>
<div class="title-wrapper">
    <span>{{courseTitle}}</span>
</div>
<div class="intro-title">יש להזין את קוד האימות שקיבלת עכשיו ב SMS</div>
<form class="input-wrapper" action="submit">
    <input autocomplete="off" type="number" maxlength="4" name="code" [(ngModel)]="code">
    <button #submitButton style="display: none;" type="submit" (click)="verify()"></button>
</form>
<div [class.show]="showWrong" class="wrong">הקוד שהזנת שגוי</div>
<a *ngIf="showRetry" class="retry" [routerLink]="" (click)="retry()">קבל קוד חדש</a>
<div class="gap"></div>
<div class="footy" (click)="submitButton.click()">המשך</div>