<block-ui>
  <header *ngIf="!hideHeader">
      <div class="flex-bottom">
          <img src="/assets/MomenTeken-logo.png" height="38">
          <div class="system-title mobile-hide">
              <span class="white fs20">מערכת לניהול לומדות</span>
              <span class="green fs20">|</span>
              <span class="green fs20">עובד בטוח</span>
          </div>
      </div>
      <div class="flex-center username mobile-hide">
          <div *ngIf="isLogin() | async" class="flex-bottom">
              <span class="white">{{getUserName()}}</span>
              <img class="margin-h-13" src="/assets/header-avatar.png" height="29">
              <div class="logout-div" (click)="logOut()">
                  <span> יציאה</span>
                  <img src="assets/logout.png">
              </div>
          </div>
          <img src="/assets/header-green-vert.svg" height="36">
      </div>
  </header>
  <div class="content">
      <router-outlet></router-outlet>
  </div>
</block-ui>