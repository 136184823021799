import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { LoginComponent } from './login/login.component'
import { FormsModule } from '@angular/forms'
import { routing } from './login.routing'

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    routing
  ],
  declarations: [LoginComponent]
})
export class LoginModule {}
