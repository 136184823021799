import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { StoreModule } from '@ngrx/store';

import { routing } from './main-module.routing';
import { MainComponent } from './main/main.component';
import { SidenavComponent } from './sidenav/sidenav.component';
import { EmployeeListComponent } from './employee-list/employee-list.component';
import { EmployeeArchiveComponent } from './employee-archive/employee-archive.component';
import { EmployeeEditComponent } from './employee-edit/employee-edit.component';
import { CourseAssignComponent } from './course-assign/course-assign.component';
// import { employeeReducer } from '../stores/employee/employee.reducer';
import { EmployeeService } from './services/employee.service';
import { CheckboxComponent } from './employee-list/checkbox/checkbox.component';
import { EmployeeChangesGuard } from './employee-changes-guard/employee-changes-guard.guard';
import { CourseService } from './services/couse.service';
import { employeeReducer } from '../stores/employee/employee.reducer';
import { appReducres } from '../stores/app/app.reducer';
import { AssignService } from './services/assign.service';
import { HistoryService } from './services/history.service';
import { AssignChangesGuard } from './assign-changes-guard/assign-changes.guard';
import { PusherService } from './services/pusher.service';



@NgModule({
  declarations: [MainComponent, SidenavComponent, EmployeeListComponent, EmployeeArchiveComponent, EmployeeEditComponent, CourseAssignComponent, CheckboxComponent],
  imports: [
    CommonModule,
    FormsModule,
    routing
  ],
  providers: [EmployeeChangesGuard,AssignChangesGuard,EmployeeService,AssignService,HistoryService,PusherService]
})
export class MainModule {
}
