import { createSelector } from '@ngrx/store';
import { AppState } from '../app/app.state';
import { Course } from '../course/course.model';
import { selectCourseList } from '../course/course.selector';
import { Employee } from '../employee/employee.model';
import { selectEmployeeList } from '../employee/employee.selector';
import { History } from './history.model';
import { HistoryState } from './history.state';

const selectHistory = (state:AppState) => state.history;

export const selectEmployeeHistory = createSelector(
    selectHistory,
    selectCourseList,
    (state:HistoryState, courses) => {
        let rtnList:{course:Course,list: History[]}[] = [];
        let keys = Object.keys(state.employee.map);
        for (let c_id of keys){
            let crs = courses.find(c => c.id == +c_id);
            if (crs){
                rtnList.push({course: crs,list:state.employee.map[c_id]})
            }
        }
        return rtnList
    }
);
export const selectEmployeeOfHistory = createSelector(
    selectHistory,
    (state:HistoryState) => state.employee.subject
);
export const selectCourseHistory = createSelector(
    selectHistory,
    selectEmployeeList,
    (state:HistoryState, employees) => {
        let tempList:{employee:Employee,list: History[]}[] = [];
        let keys = Object.keys(state.course.map);
        for (let e_id of keys){
            let emp = employees.find(c => c.id == +e_id);
            if (emp){
                tempList.push({employee: emp, list: state.course.map[e_id]})
            }
        }
        let rtnList:{employee:Employee,last: History}[] = [];
        for (let obj of tempList){
            let lastHistory = obj.list.slice().sort((a,b) => {
                return new Date(b.date).getTime() - new Date(a.date).getTime();
            })[0];//()
            rtnList.push({employee:obj.employee,last:lastHistory});
        }
        return rtnList
    }
);