import { ModuleWithProviders, NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CourseAccessModule } from './course-access-module/course-access.module';
import { LoggedInGuard } from './logged-in.guard';
import { LoginModule } from './login-module/login.module';
import { MainModule } from './main-module/main-module.module';
import { OpenAccessSmsAuthModule } from './open-access-sms-auth/open-access-sms-auth.module';
import { OpenAccessModule } from './open-access/open-access.module';

export const routes: Routes = [
    { path: 'login', loadChildren: () => LoginModule },
    { path: 'logout', loadChildren: () => LoginModule, data: {logout:true} },
    // { path: 'access/:c_id/:token', loadChildren: './course-access-module/course-access.module#CourseAccessModule' },
    { path: 'access', loadChildren: () => CourseAccessModule },
    { path: 'oate', loadChildren: () => OpenAccessModule },
    { path: 'oasm', loadChildren: () => OpenAccessSmsAuthModule },
    // { path: 'access', loadChildren: './course-access-module/course-access.module#CourseAccessModule' },
    { path: 'course', loadChildren: () => CourseAccessModule },
    { path: '', loadChildren: () => MainModule, canLoad: [LoggedInGuard], runGuardsAndResolvers: 'always', canActivate: [LoggedInGuard] },
    { path: '**', loadChildren: () => LoginModule }
];

export const routing: ModuleWithProviders<RouterModule> = RouterModule.forRoot(routes, { onSameUrlNavigation: 'reload', relativeLinkResolution: 'legacy' });

// @NgModule({
//   imports: [RouterModule.forRoot(routes)],
//   exports: [RouterModule]
// })
// export class AppRoutingModule { }
