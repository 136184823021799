import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router, UrlSegment } from '@angular/router';
import { throws } from 'assert';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter } from 'rxjs/operators';
import { languages } from 'src/app/api-service/api.service';
import { Employee } from 'src/app/stores/employee/employee.model';
import { EmployeeService } from '../services/employee.service';

@Component({
  selector: 'app-employee-edit',
  templateUrl: './employee-edit.component.html',
  styleUrls: ['./employee-edit.component.scss']
})
export class EmployeeEditComponent implements OnInit, OnDestroy {

  languages;
  //  = [
  //   {code:'he',title:'עברית'},
  //   {code:'en',title:'אנגלית'},
  //   {code:'ar',title:'ערבית'},
  //   {code:'ru',title:'רוסית'},
  //   {code:'am',title:'אמהרית'},
  //   {code:'ot',title:'אחר'}
  // ];
  private _departments$:Observable<string[]>;
  departments:string[];
  private _roles$:Observable<string[]>;
  roles:string[];
  holder:{dirty:Employee};
  showArchiveBtn$:BehaviorSubject<boolean>;
  constructor(private employeeService: EmployeeService, private route: ActivatedRoute, private router: Router) {
    this._departments$ = employeeService.getDepartments();
    this._departments$.subscribe(arr => this.departments = arr);
    this._roles$ = employeeService.getRoles();
    this._roles$.subscribe(arr => this.roles = arr);
    this.holder = this.employeeService.dirtyEmployeeHolder;
    this.showArchiveBtn$ = new BehaviorSubject<boolean>(false);
    this.languages = languages;
  }

  ngOnInit(): void {
    this.route.paramMap.subscribe(params => {
      if (params.get('e_id')){
        this.employeeService.setEditingEmployee(+params.get('e_id'));
      } else {
        this.employeeService.setEditingEmployee(-1);
      }
    });
    this.route.url.subscribe(segments => {
      if (segments.find(seg => seg.toString() == 'add')){
        this.showArchiveBtn$.next(false);
      } else {
        this.showArchiveBtn$.next(true);
      }
    })
  }

  ngOnDestroy(): void {
    this.employeeService.setEditingEmployee(-1);
    this.showArchiveBtn$.complete();
  }

  clickOnLang(code:string){
    if (this.holder.dirty.languages.indexOf(code) >= 0){
      this.holder.dirty.languages = this.holder.dirty.languages.filter(l => l != code);
    } else {
      this.holder.dirty.languages.push(code);
    }
  }
  departmentFiltered(){
    return this.departments.filter(d => !this.holder.dirty.department || d.includes(this.holder.dirty.department))
  }
  setDepartment(department:string){
    this.holder.dirty.department = department;
  }
  roleFiltered(){
    return this.roles.filter(d => !this.holder.dirty.role || d.includes(this.holder.dirty.role))
  }
  setRole(role:string){
    this.holder.dirty.role = role;
  }
  archiveClick(){
    this.employeeService.markArchive().then(bool => {
      if (bool){
        this.router.navigate(['/'], { /*relativeTo: this.route.parent*/ });
      } else {
        throw new Error('ERROR: N.S.T.H.');
      }
    }).catch(reason => alert(reason.error));
  }
  saveClick(){
    this.employeeService.saveDirty().then(e_id => {
      if (e_id > 0){
        this.router.navigate(['/'], { /*relativeTo: this.route.parent,*/ state: { employee_id: e_id } });
      } else {
        throw new Error('ERROR: N.S.T.H.');
      }
    }).catch(reason => alert(reason.error));
  }
}
