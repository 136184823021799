import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { CourseService } from 'src/app/main-module/services/couse.service';
import { Course } from 'src/app/stores/course/course.model';
import { ApiService, getLangHebrewTitle } from 'src/app/api-service/api.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-courses',
  templateUrl: './courses.component.html',
  styleUrls: ['./courses.component.scss']
})
export class CoursesComponent implements OnInit {

  courses$:Observable<Course[]>;
  constructor(private courseService: CourseService, private router: Router, private route: ActivatedRoute, private api: ApiService) { }

  ngOnInit(): void {
    this.courseService.initStore(true)
    this.courses$ = this.courseService.getCourseList();
  }

  selectCourse(course:Course): void {
    if (course){
      this.courseService.selectedCourse = {course:course, lang: null};
    } else {
      this.courseService.selectedCourse = null;
    }
  }
  
  isCourseSelected(course?:Course){
    if (!course){
      return !!this.courseService.selectedCourse
    }
    return this.courseService.selectedCourse?.course === course
  }
  getLanguages(){
    return this.courseService.selectedCourse?.course.languages
  }
  selectLanguage(lang:string){
    if (this.courseService.selectedCourse){
      if (this.courseService.selectedCourse.lang === lang){
        this.courseService.selectedCourse.lang = null;
      } else {
        this.courseService.selectedCourse.lang = lang;
      }
    }
  }
  isLangSelected(lang?:string){
    if (!lang){
      return !!this.courseService.selectedCourse?.lang
    }
    return this.courseService.selectedCourse?.lang === lang
  }
  getLangHebrewTitle = getLangHebrewTitle;

  startCourse(){
    if (this.courseService.selectedCourse){
      this.api.setCourseAccess(this.courseService.selectedCourse.course.id).subscribe(res => {
        if (res.status == 200){
          let navigation = [ this.courseService.selectedCourse.course.id+'' ];
          if (this.courseService.selectedCourse.lang){
            navigation.push(this.courseService.selectedCourse.lang);
          }
          this.router.navigate(navigation, {relativeTo: this.route });
        }
      }, err => alert(err));
    }
  }

}
