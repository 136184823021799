import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { filter, map } from 'rxjs/operators';
import { OATESmsService } from '../oate-sms.service';

@Component({
  selector: 'app-verify-phone',
  templateUrl: './verify-phone.component.html',
  styleUrls: ['./verify-phone.component.scss'],
  host: {
    '[class]': '"hide-scrollbar"'
  }
})
export class VerifyPhoneComponent implements OnInit {

  code: string;
  logoUrl: string;
  courseTitle: string;
  courseId: number;
  showWrong: boolean = false;
  showRetry: boolean = false;

  constructor(private route: ActivatedRoute, private api: OATESmsService) { }

  ngOnInit(): void {
    this.route.params.pipe(map(params => params.c_id), filter(c_id => !isNaN(+c_id))).subscribe(c_id => {
      this.api.isSessionActive(c_id).subscribe(res => {
        console.log('still active');
      }, err => {
        console.log('not active');
        history.back();
      })
      this.api.getLogoAndTitle(c_id).subscribe(obj => {
        this.logoUrl = obj.logoUrl;
        this.courseTitle = obj.title;
        this.courseId = c_id;
      });
    });
    window.setTimeout(() => {
      this.showRetry = true;
    }, 1000 * 30);
  }

  verify(): void {
    if (this.code){
      this.api.verifySmsCode(this.courseId, this.code).subscribe(res => {
        // window.open(res.body, '_self');
        location.reload();
      }, err => {
        this.showWrong = true;
      });
    }
  }

  retry(): void {
    this.api.generateNewCode(this.courseId).subscribe(res => {
      if (res.success){
        this.showWrong = this.showRetry = false;
      } else {
        history.back();
      }
    });
  }
}
