import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import { BlockUIModule } from 'ng-block-ui';
import { BlockUIHttpModule } from 'ng-block-ui/http';

import { routing } from './app-routing.module';
import { AppComponent } from './app.component';
import { filterHttpRequest } from './api-service/api.service';
import { UnauthorizedInterceptor } from './api-service/http-interceptors/unauthorized.interceptor';
import { appReducres } from './stores/app/app.reducer';
import { StoreModule } from '@ngrx/store';
import { CourseService } from './main-module/services/couse.service';
import { LanguagesResolver } from './open-access/languages.resolver';

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    FormsModule,
    HttpClientModule,
    routing,
    BlockUIModule.forRoot(),
    BlockUIHttpModule.forRoot({requestFilters: [filterHttpRequest]}),
    StoreModule.forRoot(appReducres)
  ],
  providers: [{ provide: HTTP_INTERCEPTORS, useClass: UnauthorizedInterceptor, multi: true }, CourseService],
  bootstrap: [AppComponent]
})
export class AppModule { }
