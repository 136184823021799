import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ApiAccessService {

  readonly API_URL = 'https://panel.momenteken-college.co.il/oate';

  constructor(private http: HttpClient) { }

  getCourseLanguages(courseId: number){
    return this.http.get<{id: string, name: string, name_h: string}[]>(`${this.API_URL}/course/${courseId}/languages`);
  }

  getEmployeeCompanies(courseId: number, idnum: string = '037971918'){
    return this.http.post<{employee: any, shippers: any[]}>(`${this.API_URL}/course/${courseId}/companies`, {idnum: idnum});
  }

  updateSessionPriorityUser(courseId: number, body:any){
    return this.http.post(`${this.API_URL}/course/${courseId}/start`,body,{ withCredentials: true, observe: 'events', responseType: 'text', reportProgress: true});
  }
}