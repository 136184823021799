import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'checkbox',
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.scss']
})
export class CheckboxComponent implements OnInit {
  @Output('check-click') evem = new EventEmitter();
  @Input('status') checkStatus:'checked'|'partial'|undefined;
  @Input() text:string;
  constructor() { }

  ngOnInit(): void {
  }

  checkClick(){
    this.evem.emit('boom');
  }

}
