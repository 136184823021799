import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from 'src/app/api-service/api.service';
  
@Component({
  selector: 'app-access',
  templateUrl: './access.component.html',
  styleUrls: ['./access.component.scss']
})
export class AccessComponent implements OnInit {
  idRatherThanPassport:boolean = true;
  error:string = '';
  inputValue:string = '';
  readonly INVALID_NUMBER = 'מספר לא תקין';
  readonly INVALID_INPUT = 'יש להזין 9 ספרות';
  readonly NO_INPUT = 'יש להזין מספר זיהוי';
  readonly WRONG_NUMBER = 'מספר שגוי';
  readonly NOT_FOUND = 'עובד לא נמצא';
  readonly NOT_FOUND_ASSIGN = 'לא נמצא זימון';
  constructor(private api: ApiService, private router: Router, private route: ActivatedRoute) { }

  ngOnInit(): void {
  }
  idClick(){
    if (!this.idRatherThanPassport){
      this.idRatherThanPassport = true;
      this.inputValue = '';
    }
  }
  passportClick(){
    if (this.idRatherThanPassport){
      this.idRatherThanPassport = false;
      this.inputValue = '';
    }
  }
  inputKeyup(event){
    var target = event.srcElement || event.target;
    var maxLength = parseInt(target.attributes["maxlength"].value, 10);
    var myLength = target.value.length;
    this.inputValue = target.value;
    if (myLength >= maxLength){
      target.blur();
      if (!this.isValidID(this.inputValue)){
        this.error = this.INVALID_NUMBER;
        setTimeout(() => {this.error = ''},3000);
      }
    }
  }
  inputKeydown(e){
    var target = e.srcElement || e.target;
    var maxLength = parseInt(target.attributes["maxlength"].value, 10);
    var myLength = target.value.length;
    if (myLength >= maxLength){
      if (e.which == 8 || e.which == 46)
        return true;
      return false;
    }
    if (e.shiftKey === true ) {
      if (e.which == 9) {
          return true;
      }
      return false;
    }
    if ((/[0-9]/).test(e.key)){
      return true;
    }
    if (e.which == 8 || e.which == 46){
      return true;
    }
    return false;
  }
  isValidID(str:string){
    var mone = 0, incNum;
    for (var i=0; i < 9; i++) {
       incNum = Number(str.charAt(i));
       incNum *= (i%2)+1;
       if (incNum > 9)
          incNum -= 9;
       mone += incNum;
    }
    if (mone%10 == 0)
       return true;
    else
       return false;
  }
  toDoClick(){
    if (this.inputValue.length == 0){
      this.error = this.NO_INPUT;
      setTimeout(() => {this.error = ''},3000);
      return;
    }
    if (this.idRatherThanPassport){
      if (this.inputValue.length != 9){
        this.error = this.INVALID_INPUT;
        setTimeout(() => {this.error = ''},3000);
        return;
      }
      if (!this.isValidID(this.inputValue)){
        this.error = this.INVALID_NUMBER;
        setTimeout(() => {this.error = ''},3000);
        return;
      }
    }
    let url;
    let segments = this.route.snapshot.url;
    let seg = segments.find(s => s.path == 'courses');
    if (seg){
      let index = segments.indexOf(seg);
      let segCourseId = segments[index+1];
      let segLangId = segments.length > (index+2) ? segments[index+2] : null;
      if (segLangId){
        url = this.api.ROOT_URL + '/access/'+segCourseId+'/'+segLangId;
      } else {
        url = this.api.ROOT_URL + '/access/'+segCourseId;
      }
    } else {
      url = this.router.url;
    }
    this.api.setIdenToAccess(url,this.inputValue).subscribe(
      value => {
        if (value){
          window.open(value,'_self');
        }
      },
      error => {
        if (error.status == 406){
          if (seg){
            this.error = this.NOT_FOUND_ASSIGN;
          } else {
            this.error = this.WRONG_NUMBER;            
          }
          setTimeout(() => {this.error = ''},3000);
        } else if (error.status == 404) {
          this.error = this.NOT_FOUND;
          setTimeout(() => {this.error = ''},3000);
        } else {
          console.log(error);
          alert("שגיאה! קוד "+error.status)
        }
      }
    );
  }
}
