import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { InsertDetailsComponent } from './insert-details/insert-details.component';
import { VerifyPhoneComponent } from './verify-phone/verify-phone.component';

@NgModule({
  declarations: [InsertDetailsComponent, VerifyPhoneComponent],
  imports: [
    CommonModule,
    FormsModule,
    RouterModule.forChild([
      { path: ':c_id', component: InsertDetailsComponent, pathMatch: 'full' },
      { path: ':c_id/verify', component: VerifyPhoneComponent, pathMatch: 'full' }
    ])
  ]
})
export class OpenAccessSmsAuthModule { }
