<div class="custom-checkbox-wrapper">
    <label (click)="$event.stopPropagation()">
        <input (click)="checkClick()" type="checkbox"
            [checked]="checkStatus == 'checked' ? true : undefined" [required]="checkStatus == 'partial' ? true : undefined" />
        <span class="checkmark">
            <img src="assets/v-icon-t.png" class="v-icon">
            <img src="assets/o-icon-t.png" class="o-icon">
        </span>
        <span>{{text}}</span>
    </label>
</div>