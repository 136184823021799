<div class="icon">
    <img src="assets/bluewy.svg">
    <div class="inner-icon">
        <img src="assets/list-page-icon.png">
    </div>
</div>

<div class="list-section">
    <span class="title">רשימת עובדים</span>
    <div #filter class="filter-wrapper" *ngIf="obj.store.filterIsOpen" [ngStyle]="{'right': obj.store.filterRight + 'px' }" [ngClass]="{'higher' : higher, 'wider' : wider}">
        <div class="search-wrapper">
            <input type="text" placeholder="חיפוש" (keyup)="onFilterSearchChange($event)" [(ngModel)]="obj.filterSearchValue">
        </div>
        <checkbox class="select-all" (check-click)="checkAllFilter()" [status]="isAllFilterChecked()" [text]="'בחר הכל'"></checkbox>
        <div class="options-wrapper">
            <div *ngFor="let op of obj.store.searchValues">
                <checkbox class="select-all" (check-click)="doCheck(op)" [status]="op.check ? 'checked' : undefined" [text]="op.value"></checkbox>
            </div>
        </div>
        <div class="btn-wrapper">
            <div class="filter-btn" (click)="closeFilter(true)">החל</div>
        </div>
    </div>
    <div class="head-row">
        <div style="width: 78px;">
            <span>מ. עובד</span>
            <img src="assets/filter-arrow.png" (click)="openFilter('number')">
        </div>
        <div style="width: 85px;">
            <span>שם פרטי</span>
            <img src="assets/filter-arrow.png" (click)="openFilter('firstName')">
        </div>
        <div style="width: 105px;">
            <span>שם משפחה</span>
            <img src="assets/filter-arrow.png" (click)="openFilter('surname')">
        </div>
        <div style="width: 110px;">
            <span>תעודת זהות</span>
            <img src="assets/filter-arrow.png" (click)="openFilter('identity')">
        </div>
        <div style="width: 73px;">
            <span>סניף</span>
            <img src="assets/filter-arrow.png" (click)="openFilter('site')">
        </div>
        <div style="width: 80px;">
            <span>מחלקה</span>
            <img src="assets/filter-arrow.png" (click)="openFilter('department')">
        </div>
        <div style="width: 100px;">
            <span>תפקיד</span>
            <img src="assets/filter-arrow.png" (click)="openFilter('role')">
        </div>
    </div>
    <div class="rows-wrapper-wrapper">
        <div class="rows-wrapper hide-scrollbar" #scrollable>
            <div *ngFor="let emp of obj.store.displayEmployees" #row class="single-row" [attr.selected]="isSelected(emp)" (click)=selectEmployee(emp)>
                <div style="width: 78px;">
                    <span [attr.title]="emp.number">{{emp.number}}</span>
                </div>
                <div style="width: 85px;">
                    <span [attr.title]="emp.firstName">{{emp.firstName}}</span>
                </div>
                <div style="width: 105px;">
                    <span [attr.title]="emp.surname">{{emp.surname}}</span>
                </div>
                <div style="width: 110px;">
                    <span [attr.title]="emp.identity">{{emp.identity}}</span>
                </div>
                <div style="width: 73px;">
                    <span [attr.title]="emp.site">{{emp.site}}</span>
                </div>
                <div style="width: 80px;">
                    <span [attr.title]="emp.department">{{emp.department}}</span>
                </div>
                <div style="width: 100px;">
                    <span [attr.title]="emp.role">{{emp.role}}</span>
                </div>
            </div>
        </div>
    </div>
    <div class="bottom-btns-wrapper">
        <div class="xlsx-btn" (click)="fileInput.click()">
            <span>ייבוא מרשימה</span>
            <img src="assets/xlsx.png">
            <input style="display: none" type="file" accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" (change)="importSubmit($event);" #fileInput>
        </div>
        <div class="xlsx-btn" (click)="exportList()">
            <span>ייצוא לרשימה</span>
            <img src="assets/xlsx.png">
        </div>
        <div class="archive-btn" [routerLink]="'../archive'">
            <span>כניסה לארכיון</span>
            <img src="assets/archive.png">
        </div>
    </div>
    <a style="font-size: 16px; text-decoration: underline; cursor: pointer; margin-right: 284px; margin-bottom: 10px;" href="assets/sample.xlsx">יש להעלות לפי התבנית לדוגמה</a>
    <img src="assets/selected-arrow.svg" class="selected-arrow" [ngStyle]="{'display': getSelected() ? 'block' : 'none', 'top': (arrowOffset ? arrowOffset : undefined) +'px'  }">
</div>

<div class="single-section">
    <div class="content-wrapper" *ngIf="getSelected(); let emp">
        <div class="emp-name ellipsis" [attr.title]="emp.firstName+' '+emp.surname">{{emp.firstName}} {{emp.surname}}</div>
        <div class="more-info" [routerLink]="'../edit/'+emp.id">
            <div class="phone-wrapper">
                <div class="title">נייד</div>
                <span class="content" [attr.title]=emp.phone>{{emp.phone}}</span>
            </div>
            <div class="email-wrapper">
                <div class="title">דוא&quot;ל</div>
                <span class="content" [attr.title]=emp.email>{{emp.email}}</span>
            </div>
            <div class="for-full-info">לחץ למידע מלא ></div>
        </div>
        <div class="future-assign">
            <div class="title">זימון להדרכות עתידיות</div>
            <div class="content hide-scrollbar" [ngClass]="{'loading' : !(isAssignsResolve() | async) }">
                <img class="gif-loading" src="assets/loading.gif">
                <div class="date-lang-title"><span style="padding-left: 50px;">שפה</span><span style="padding-left: 17px;">תאריך שליחה</span></div>
                <div *ngFor="let assign of getAssigns() | async" class="assign-row">
                    <span class="x" (click)="removeAssign(assign)">x</span>
                    <span class="name">{{assign.title}}</span>
                    <span class="lang">{{getLangHebrewTitle(assign.lang)}}</span>
                    <span class="date">{{assign.sent | date: 'dd.MM.yy'}}</span>
                    <!-- נשלח ב- -->
                </div>
            </div>
        </div>
        <div class="history-wrapper">
            <div class="title">הסטוריית הדרכות</div>
            <div class="content hide-scrollbar" [ngClass]="{'loading' : !(isHistoryResolve() | async) }">
                <img class="gif-loading" src="assets/loading.gif">
                <div *ngFor="let history of getHistory() | async" class="history-row">
                    <span class="name ellipsis">{{history.course.title}}</span>
                    <!-- <span>{{history.list[0].date | date: 'dd.MM.yy'}}</span> -->
                    <span class="pass" (click)="setSelectedHistory(history)" [ngStyle]="{'backgroundColor': history.list[0].pass ? 'rgba(202, 249, 130, 1)' : 'rgba(249, 209, 130, 1)' }">{{history.list[0].pass ? 'עבר' : 'לא עבר'}}</span>
                </div>
            </div>
            <div class="course-details" *ngIf="getSelectedHistory(); let history">
                <div class="head"><span>לומדה</span><span class="x" (click)="setSelectedHistory(null)">x</span></div>
                <div class="course-title ellipsis" [attr.title]="history.course.title">{{history.course.title}}</div>
                <div class="print-wrapper">
                    <div class="pass" [ngStyle]="{'backgroundColor': history.list[0].pass ? 'rgba(202, 249, 130, 1)' : 'rgba(249, 209, 130, 1)' }">{{history.list[0].pass ? 'עבר' : 'לא עבר'}}</div>
                    <div *ngIf="history.list[0].pass" class="print" (click)="printCertificate()"><span class="text">הדפסת תעודה</span><img src="assets/printer.png"></div>
                </div>
                <div class="more-details">
                    <div class="date">
                        <img src="assets/calendar.png">
                        <div class="label">תאריך אחרון</div>
                        <div class="data">{{history.list[0].date | date: 'dd.MM.yy'}}</div>
                    </div>
                    <div class="score">
                        <img src="assets/cert.png">
                        <div class="label">ציון</div>
                        <div class="data">{{history.list[0].score}}</div>
                    </div>
                    <div class="previous hide-scrollbar">
                        <div class="prev-label">תאריכים קודמים</div>
                        <div class="date-row" *ngFor="let hist of history.list | slice:1">
                            <span class="date-data">{{hist.date | date: 'dd.MM.yy'}}</span>
                            <span>{{hist.pass ? 'עבר' : 'לא עבר'}}</span>
                            <span class="score-data">{{hist.score}}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>