import { Assign } from '../assign/assign.model';
import { History } from '../history/history.model';

export class Employee {
    id: number;
    number: string;
    firstName: string;
    surname: string;
    identity: string;
    site: string;
    department: string;
    role: string;
    leavingDate: Date;
    phone: string;
    email: string;
    languages: string[];
    comments: string;
    constructor(props:any){
        let keys = Object.keys(props);
        for (let key of keys){
            this[key] = props[key];
        }
    }
    static compare = (e1:Employee,e2:Employee) => {
        if (!e2){
            return false;
        }
        const keys1 = Object.keys(e1);
        const keys2 = Object.keys(e2);

        if (keys1.length !== keys2.length) {
            return false;
        }

        for (let key of keys1) {
            if (e1[key] instanceof Array){
                if (e2[key] instanceof Array){
                    if (e1[key].length !== e2[key].length) {
                        return false;
                    }
                    for (let i = 0; i < e1[key].length; i++){
                        if (e1[key][i] !== e2[key][i]){
                            return false;
                        }
                    }    
                } else {
                    return false;
                }
            } else if (e1[key] !== e2[key]) {
                return false;
            }
        }

        return true;
    }
}
export class EmployeePlus extends Employee {
    statusText:string;
    statusColor:string;
    selected:boolean;
    constructor(emp:Employee, props?:any){
        super({...emp});
        if (props){
            let keys = Object.keys(props);
            for (let key of keys){
                this[key] = props[key];
            }
        }
    }
}