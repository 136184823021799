import { Component, OnDestroy, OnInit } from '@angular/core';
import { CourseService } from '../services/couse.service';
import { EmployeeService } from '../services/employee.service';

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss']
})
export class MainComponent implements OnInit, OnDestroy {

  constructor(
    private employeeService:EmployeeService,
    private courseService:CourseService
    ) {}

  ngOnInit(): void {
    this.employeeService.initStore();
    this.courseService.initStore();
  }
  ngOnDestroy(): void {
    this.employeeService.clearStore();
    this.courseService.clearStore();
  }

}
