import { Injectable } from '@angular/core';
import { Router, Resolve, RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';
import { filter, take } from 'rxjs/operators';
import { ApiAccessService } from './api-access.service';
import { shareService } from './language.service';

@Injectable({
  providedIn: 'root'
})
export class LanguagesResolver implements Resolve<{id: string, name: string, name_h: string}> {
  constructor(private api: ApiAccessService, private share: shareService){}
  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<{id: string, name: string, name_h: string}> {
    let courseId = +route.paramMap.get('c_id');
    let langId = route.paramMap.get('lang');
    if (!this.share.language$.value || !this.share.courseId$.value){
      this.api.getCourseLanguages(courseId).subscribe(res => {
        this.share.courseId$.next(courseId);
        let found = res.find(lang => lang.id === langId);
        if (found)
          this.share.language$.next(found);
        else
          console.log('no lang');
      });
    }
    return this.share.language$.pipe(filter(x => !!x), take(1));
  }
}
