import { Course } from '../course/course.model';
import { Employee } from '../employee/employee.model';
import { Assign } from './assign.model';

export interface AssignState {
    employee: {
        subject: Employee,
        list: Assign[]
    },
    course: {
        subject: Course,
        list: Assign[]
    }
}

export const initialAssignState: AssignState = {
    employee: {
        subject: null,
        list: []
    },
    course: {
        subject: null,
        list: []
    },
};