import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { filter, map } from 'rxjs/operators';
import { OATESmsService } from '../oate-sms.service';

@Component({
  selector: 'app-insert-details',
  templateUrl: './insert-details.component.html',
  styleUrls: ['./insert-details.component.scss'],
  host: {
    '[class]': '"hide-scrollbar"'
  }
})
export class InsertDetailsComponent implements OnInit {
  
  name: string;
  idnum: string;
  phone: string;
  logoUrl: string;
  courseTitle: string;
  courseId: number;
  submitted: boolean;
  hideRed: boolean;
  sendingError: boolean;

  constructor(private route: ActivatedRoute, private api: OATESmsService, private router: Router) { }

  ngOnInit(): void {
    this.route.params.pipe(map(params => params.c_id), filter(c_id => !isNaN(+c_id))).subscribe(c_id => {
      this.api.getLogoAndTitle(c_id).subscribe(obj => {
        this.logoUrl = obj.logoUrl;
        this.courseTitle = obj.title;
        this.courseId = c_id;
      });
    });
  }

  phoneError(): string {
    if (!this.phone && this.submitted) return 'חובה למלא'; let cleaned: string;
    if (this.phone && !(cleaned = this.normalizePhoneNumber(this.phone))) return 'מספר נייד לא תקין';
    if (this.sendingError) return 'לא ניתן לשלוח';
    return null;
  }
  
  normalizePhoneNumber(phone: string): string {
      if (!phone || !(typeof phone == 'string') || phone.length == 0 || phone.match(/[^+\-0-9\s]/)) {
          return null;
      }
      let cleaned = ('' + phone).replace(/\D/g, ''); //remove all not-digit characters
      if (cleaned.startsWith('972')) {
          cleaned = cleaned.replace('972', '0');
      }
      if (cleaned.length != 10 || !cleaned.startsWith('05')) {
          return null;
      }
      cleaned = cleaned.substring(0, 3) + '-' + cleaned.substring(3);
      return cleaned;
  }

  nextClick(): void {
    this.submitted = true;
    this.hideRed = true;
    window.setTimeout(() => this.hideRed = false, 250);
    if (this.name && this.idnum && this.normalizePhoneNumber(this.phone)){
      this.api.sendForSmsCode(this.courseId, this.name, this.idnum, this.normalizePhoneNumber(this.phone)).subscribe(res => {
        if (res.success){
          this.router.navigate(['verify'], {relativeTo: this.route, skipLocationChange: true, replaceUrl: false});
        } else {
          this.sendingError = true;
          window.setTimeout(() => this.sendingError = false, 1000);
        }
      }, err => {
        console.error(err);
        this.sendingError = true;
        window.setTimeout(() => this.sendingError = false, 1000);
      });
    }
  }
}
