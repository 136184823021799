<div class="sidenav-wrapper">
    <div class="logo-wrapper">
        <img [src]="user.clientLogoUrl" class="logo">
    </div>
    <div class="title-wrapper">
        <span>{{user.company_name}}</span>
    </div>
    <div class="options-wrapper">
        <div *ngFor="let option of options" class="single-option" [attr.selected]="option.chosen" [routerLink]="option.route">
            <span>{{option.text}}</span>
            <img [src]="option.src">
        </div>
    </div>
</div>