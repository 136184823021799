import { Component, isDevMode } from '@angular/core';
import { UserService } from './user-service/user.service';
import { NavigationEnd, Router } from '@angular/router';
import { filter, map, throttleTime } from 'rxjs/operators';
import { fromEvent } from 'rxjs';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  hideHeader: boolean = false;
  constructor(private userService : UserService, private router: Router){
    this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe((ne: NavigationEnd) => {
      console.log(ne);
      this.hideHeader = ne.url.includes('oasm');
    });
    fromEvent(window, 'resize').pipe(throttleTime(16.666)).subscribe(event => {
      // https://css-tricks.com/the-trick-to-viewport-units-on-mobile/
      let vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty('--vh', `${vh}px`);
    });
  }
  isLogin(){
    // return this.userService.isLogin() || this.userService.isLoginForCourses();
    return this.userService.isLogin$.pipe(map(obj => obj.val));
  }
  getUserName(){
    return this.userService.getName()
  }
  logOut(){
    // this.userService.logout();
    this.router.navigate(['/logout']);
  }
}