import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { BehaviorSubject, Observable, of, Subject, Subscription } from 'rxjs';
import { ApiService } from '../../api-service/api.service';
import { Employee } from '../../stores/employee/employee.model';
import * as cloneDeep from 'lodash/cloneDeep';
// import { list as GET_LIST, archive as ARCHIVE, active as ACTIVE, edit as UPDATE, add as ADD } from '../stores/employee/employee.actions';
import { map, take } from 'rxjs/operators';
import { DatePipe, registerLocaleData } from '@angular/common';
import localeIl from '@angular/common/locales/he';
import { HttpResponse } from '@angular/common/http';
import { AddEmployee, EditEmployee, SetActive, SetArchive, SetList } from '../../stores/employee/employee.actions';
import { EmployeeState } from '../../stores/employee/employee.state';
import { selectEmployeeArchiveList, selectEmployeeList } from '../../stores/employee/employee.selector';
import { AppState } from '../../stores/app/app.state';

const clone:<T>(obj:T) => T = cloneDeep;
const removeDuplicates:<T>(arr:T[]) => T[] = (arr) => [...new Set(arr)];
const dateToString:(date:Date) => string = (d) => (new DatePipe('he')).transform(d,'dd.MM.yy');
const sodts:(o:string|Date) => string = (x) => x instanceof Date ? dateToString(x) : x;

export interface FilterListStore {
  displayEmployees?:Employee[];
  filterIsOpen?:boolean;
  lastKey?:string;
  filterRight?:number;
  allDisplayFilteredValues?: {
    value:string,
    check:boolean
  }[];
  searchValues?: {
    value:string,
    check:boolean
  }[];
  fields?: {
    [key:string]: {
      value:string,
      check:boolean
    }[]
  };
};

@Injectable()
export class EmployeeService {
  listWithFilter: {
    fields:{[key:string]:number},
    allEmployees:Employee[],
    store:FilterListStore,
    filterSearchValue:string,
    filterSearchPrevValue:string,
    allFieldsValues: {
      [key:string]: string[]
    },
    subscription: Subscription;
  };
  selectedEmployee:Employee;
  editingEmployee$:BehaviorSubject<Employee> = new BehaviorSubject(this.getEmptyEmployee());
  dirtyEmployeeHolder:{dirty:Employee} = {dirty:this.getEmptyEmployee()};
  constructor(private _store: Store<AppState>, private api: ApiService) {
    registerLocaleData(localeIl);
    this._store.dispatch(new SetList([]));
  }
  initStore(){
    this.api.getEmployeeListFromBackend().subscribe(list => this._store.dispatch(new SetList(list)));
  }
  clearStore(){
    this._store.dispatch(new SetList([]))
    // this._store.dispatch(GET_LIST({employees: null}))
  }
  getEmployeeList(archive:boolean = false){
    if (archive)
      return this._store.pipe(select(selectEmployeeArchiveList));
    else
      return this._store.pipe(select(selectEmployeeList));
  }
  insertMultiEmployees(file:any){
    this.api.insertMultipleEmployees(file).subscribe(success => {
      if (success){
        this.initStore();
      }
    });
  }
  getEmployeesXlsx(){
    this.api.getEmployeesXlsx();
  }
  initialFilterList(fields:{[key:string]:number},archive:boolean = false){
    if (this.listWithFilter && this.listWithFilter.subscription){
      this.listWithFilter.subscription.unsubscribe();
    }
    let ref = this.listWithFilter = {
      fields: fields,
      allEmployees: null,
      store: {
        displayEmployees: [],
        filterIsOpen: null,
        lastKey: null,
        filterRight: 0,
        allDisplayFilteredValues: [],
        searchValues: [],
      },
      filterSearchValue: '',
      filterSearchPrevValue: '',
      allFieldsValues: {},
      subscription: null,
    };
    // let mp = map((list:Employee[]) => list.filter(e => !e.leavingDate));
    // if (archive){
    //   mp = map((list:Employee[]) => list.filter(e => e.leavingDate));
    // }
    ref.subscription = this.getEmployeeList(archive)/*.pipe(mp)*/.subscribe(arr => {
      ref.allEmployees = arr;
      const setListReducer:FilterListStore = {
        displayEmployees: [...ref.allEmployees],
      }
      ref.store = {...ref.store, ...setListReducer };
      let keys = Object.keys(fields);
      for (let key of keys){
        ref.allFieldsValues[key] = [''];//empty value is an option to all fields
      }
      ref.allEmployees.map(emp => {
        for (let key of keys){
          if (emp[key]){
            ref.allFieldsValues[key].push(sodts(emp[key]).trim());
          }
        }
      });
      let tempWithChecks = {};
      for (let key of keys){// remove duplicates
        ref.allFieldsValues[key] = removeDuplicates(ref.allFieldsValues[key]);
        tempWithChecks[key] = ref.allFieldsValues[key].map(val => {return {value:val,check:true}});
      }
      const setInitialStateReducer:FilterListStore = {
        displayEmployees: [...ref.allEmployees],
        filterIsOpen: false,
        lastKey: null,
        filterRight: 0,
        allDisplayFilteredValues: [],
        searchValues: [],
        fields: tempWithChecks
      }
      ref.store = {...ref.store, ...setInitialStateReducer }
    });
    return ref;
  }
  openFilter(key:string){
    let ref = this.listWithFilter;
    let otherKeys = Object.keys(ref.store.fields).filter(k => k != key);
    let employees = [...ref.allEmployees];
    for (let other of otherKeys){
      let otherKeyCheckedValues = ref.store.fields[other].filter(o => o.check).map(o => o.value);
      employees = employees.filter(emp => otherKeyCheckedValues.includes(emp[other] ? sodts(emp[other]).trim() : ''));
    }
    let valuesAfterOtherFilter = ref.store.fields[key].filter(o => o.value ? employees.find(emp => emp[key] ? sodts(emp[key]).trim() == o.value : undefined) : employees.find(emp => !emp[key]));
    let clonedValues = clone(valuesAfterOtherFilter);
    const reducer:FilterListStore = {
      filterIsOpen: true,
      lastKey: key,
      filterRight: ref.fields[key],
      allDisplayFilteredValues: [...clonedValues],
      searchValues: [...clonedValues].sort((a,b) => a.value.localeCompare(b.value))
    };
    ref.store = {...ref.store,...reducer};
  }
  closeFilter(bool:boolean){
    let ref = this.listWithFilter;
    const applyFilterReducer:FilterListStore = {
      filterIsOpen: false,
      allDisplayFilteredValues: null,
      searchValues: null,
    };
    if (bool){
      // if only one result and it not checked - check it on close
      if (ref.store.searchValues.length == 1 && this.isAllFilterChecked() === undefined){
        ref.store.searchValues[0].check = true;
      }
      let tempFieldAllValues = clone(ref.store.fields[ref.store.lastKey]);
      for (let oldOp of tempFieldAllValues){
        let newOp = ref.store.allDisplayFilteredValues.find(o => o.value == oldOp.value);
        if (newOp)
          oldOp.check = newOp.check;
      }
      let wrapper:{[k:string]:{value:string,check:boolean}[]} = {};
      wrapper[ref.store.lastKey] = tempFieldAllValues;
      applyFilterReducer.fields = {
        ...ref.store.fields, ...wrapper
      }
    }
    ref.store = {...ref.store, ...applyFilterReducer };
    ref.filterSearchValue = '';
    ref.filterSearchPrevValue = '';

    const showAccordingToFilters:FilterListStore = {
      displayEmployees : [
        ...ref.allEmployees.filter(emp => {
            for (let key of Object.keys(ref.store.fields)){
              if (!ref.store.fields[key].filter(op => op.check).map(op => op.value).includes(emp[key] ? sodts(emp[key]).trim() : ''))
                return false;
            }
            return true
          }
        )
      ]
    };
    ref.store = {...ref.store, ...showAccordingToFilters };

    if (ref.store.displayEmployees.length == 1){
      return ref.store.displayEmployees[0]
    }
  }
  checkAllFilter(val = this.isAllFilterChecked()){
    let ref = this.listWithFilter;
    let bool = true;
    if (val == 'checked')
      bool = false;
    let tempCheckedFilteredValues = clone(ref.store.allDisplayFilteredValues);
    for (let o of tempCheckedFilteredValues){
      o.check = bool;
    }
    const reducer:FilterListStore = {
      allDisplayFilteredValues: tempCheckedFilteredValues,
      searchValues: tempCheckedFilteredValues.sort((a,b) => a.value.localeCompare(b.value))
    }
    ref.store = {...ref.store, ...reducer };
    this.applySearchFilter();
  }
  doCheck(op: {value:string,check:boolean}){
    let ref = this.listWithFilter;
    let temp = [...ref.store.allDisplayFilteredValues.filter(o => o != op), {value: op.value, check: !op.check}, ];
    const reducer:FilterListStore = {
      allDisplayFilteredValues: temp,
      searchValues: temp.sort((a,b) => a.value.localeCompare(b.value))
    }
    ref.store = {...ref.store, ...reducer };
    this.applySearchFilter();
  }
  isAllFilterChecked(){
    let ref = this.listWithFilter;
    let someChecked = false;
    let someUnchecked = false;
    for (let o of ref.store.allDisplayFilteredValues){
      if (o.check){
        someChecked = true;
      }
      if (!o.check){
        someUnchecked = true;
      }
    }
    if (someChecked && someUnchecked){
      return 'partial';
    } else if (someChecked){
      return 'checked';
    } else{
      return undefined;
    }
  }
  applySearchFilter(){
    let ref = this.listWithFilter;
    const reducer:FilterListStore = {
      searchValues: ref.store.allDisplayFilteredValues.filter(o => o.value.includes(ref.filterSearchValue))//.sort((a,b) => a.value.localeCompare(b.value))
    }
    ref.store = {...ref.store, ...reducer };
  }
  getDepartments(): Observable<string[]> {
    return this._store.pipe(select(selectEmployeeList),map(list => removeDuplicates(list.filter(emp => emp.department).map(emp => emp.department.trim()))));
  }
  getRoles(): Observable<string[]> {
    return this._store.pipe(select(selectEmployeeList),map(list => removeDuplicates(list.filter(emp => emp.role).map(emp => emp.role.trim()))));
  }
  setEditingEmployee(id:number){
    this.getEmployeeList().pipe(take(1)).subscribe(list => {
      let emp = list.find(e => e.id === id);
      if (emp){
        this.editingEmployee$.next(emp);
        this.dirtyEmployeeHolder.dirty = clone(emp);
      } else {
        this.editingEmployee$.next(null);
        this.dirtyEmployeeHolder.dirty = this.getEmptyEmployee();
      }
    });
  }
  getEmptyEmployee():Employee{
    return new Employee({
      id: -1,
      number:'',
      firstName:'',
      surname:'',
      identity:'',
      site:'',
      department:'',
      role:'',
      leavingDate:null,
      phone:'',
      email:'',
      languages:[],
      comments:''
    });
  }
  markArchive():Promise<boolean>{
    if (this.dirtyEmployeeHolder.dirty.id > 0){
      return new Promise<boolean>((resolve,reject) => {    
          this.api.setEmployeeLeave(this.dirtyEmployeeHolder.dirty).subscribe(
            res => {
              if (res.status == 200){
                this._store.dispatch(new SetArchive({id: this.dirtyEmployeeHolder.dirty.id, leavingDate: new Date()}));
                this.setEditingEmployee(-1);
                resolve(true);
              } else {
                reject({error:'Unexpected code: '+res.status});
              }
            },reject
          );
      });
    } else {
      return new Promise<boolean>((res,rej) => rej('עובד לא נמצא'));
    }
  }
  markActive(emp:Employee):Promise<boolean>{
    return new Promise<boolean>((resolve,reject) => {    
        this.api.setEmployeeActive(emp).subscribe(
          res => {
            if (res.status == 200){
              this._store.dispatch(new SetActive(emp.id));
              resolve(true);
            } else {
              reject({error:'Unexpected code: '+res.status});
            }
          }, reject
        );
    });
  }
  saveDirty():Promise<number>{
    return new Promise((resolve,reject) => {
      if (this.dirtyEmployeeHolder.dirty.id > 0){
        let emp_id = this.dirtyEmployeeHolder.dirty.id;
        let handle = (res:HttpResponse<Employee>) => {
          if (res.status == 200){// employee update 
            this._store.dispatch(new EditEmployee(res.body));
            this.setEditingEmployee(-1);
            resolve(emp_id);
          } else if (res.status == 204){// no change
            this.setEditingEmployee(-1);
            resolve(emp_id);
          } else {
            reject({error:'Unexpected code: '+res.status});
          }
        }
        // let onError = (err:any) => {console.log(err);reject();}
        this.api.updateEmployee(this.dirtyEmployeeHolder.dirty).subscribe(handle, reject);
      } else {
        let handle = (res:HttpResponse<Employee>) => {
          if (res.status == 201){// new employee
            this._store.dispatch(new AddEmployee(res.body));
            this.setEditingEmployee(-1);
            resolve(res.body.id);
          } else {
            reject({error:'Unexpected code: '+res.status});
          }
        }
        this.api.insertEmployee(this.dirtyEmployeeHolder.dirty).subscribe(handle, reject);
      }
    });
  }
  isDirtyChanges():boolean{
    if (!this.editingEmployee$.value){
      return !Employee.compare(this.getEmptyEmployee(),this.dirtyEmployeeHolder.dirty)
    } else {
      return !Employee.compare(this.editingEmployee$.value,this.dirtyEmployeeHolder.dirty)
    }
  }
}
