import { createReducer, on} from '@ngrx/store';
import { CourseAction, CourseActions } from './course.actions';
import { Course } from './course.model';
import { CourseState, initialCourseState } from './course.state';


export const courseReducer = (
    state = initialCourseState,
    action: CourseActions
): CourseState => {
    switch (action.type) {
        case CourseAction.SetList: {
            return {
                ...state,
                list: action.payload
            };
        }
        default: {
            return state;
        }
    }
}

// export const initialState = [];

// const _courseReducer = createReducer(
//     initialState,
//     on(list, (state, { courses } ) => courses ? [ ...courses ] : [] ),
// );

// export function courseReducer(state, action) {
//     return _courseReducer(state, action);
// }