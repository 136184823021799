import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject, Observable, of, Subscription } from 'rxjs';
import { MapOperator } from 'rxjs/internal/operators/map';
import { filter, first, map, take } from 'rxjs/operators';
import { languages, getLangHebrewTitle } from 'src/app/api-service/api.service';
import { Assign } from 'src/app/stores/assign/assign.model';
import { Course } from 'src/app/stores/course/course.model';
import { Employee, EmployeePlus } from 'src/app/stores/employee/employee.model';
import { AssignService } from '../services/assign.service';
import { CourseService } from '../services/couse.service';
import { FilterListStore, EmployeeService } from '../services/employee.service';
import { HistoryService } from '../services/history.service';
import * as ExcelJS from 'exceljs';
import { DatePipe } from '@angular/common';

const removeDuplicates:<T>(arr:T[]) => T[] = (arr) => [...new Set(arr)];

@Component({
  selector: 'app-course-assign',
  templateUrl: './course-assign.component.html',
  styleUrls: ['./course-assign.component.scss']
})
export class CourseAssignComponent implements OnInit {

  courses$:Observable<Course[]>;
  allEmployees$: BehaviorSubject<EmployeePlus[]>;
  employees$: Observable<EmployeePlus[]>;
  assigns$: Observable<Assign[]>;
  tempLastEmployees: EmployeePlus[];
  sub: Subscription;
  filterHolder:{[key:string]:{valuesToChoose:string[],valueToFilter:string}} = {
    site: {
      valuesToChoose: [],
      valueToFilter: ''
    },
    department: {
      valuesToChoose: [],
      valueToFilter: ''
    },
    role: {
      valuesToChoose: [],
      valueToFilter: ''
    },
    languages: {
      valuesToChoose: [],
      valueToFilter: ''
    },
  }
  searchValue:string = '';
  selectedEmployees: Employee[] = [];
  holder:any;
  removeFromDOM:boolean = false;
  filterStatusHolder:{pass?:boolean;notPass?:boolean,sent?:boolean;notSent?:boolean} = {};//{pass:true,notPass:true,sent:true,notSent:true};
  constructor(
    private courseService: CourseService, 
    private assignService: AssignService, 
    private historyService: HistoryService, 
    private employeeService: EmployeeService,
    private route: ActivatedRoute,
    private router: Router
  ) { }

  ngOnInit(): void {
    let filterByProp = (prop:string) => {
      return map(<E extends Employee>(list:E[]) => 
        list.filter(emp => 
          !this.filterHolder[prop].valueToFilter
            || 
          (
            emp[prop] 
              && 
            (
              emp[prop] instanceof Array ? 
              emp[prop].indexOf(this.filterHolder[prop].valueToFilter) >= 0 :
              emp[prop].includes(this.filterHolder[prop].valueToFilter)
            )
          )
        )
      )
    }
    let combineFirstAndSurname = (str1:string,str2:string) => (str1 && str2) ? str1+' '+str2 : undefined;
    let filterByStatus = () => {
      return map(<E extends Employee>(list:E[]) => 
        list.filter(emp => 
          Object.keys(this.filterStatusHolder).length === 0
            || 
          !emp['statusText'] && this.filterStatusHolder.notSent
            ||
          emp['statusText']?.includes('נשלח') && this.filterStatusHolder.sent
            ||
          emp['statusText']?.includes('לא עבר') && this.filterStatusHolder.notPass
            ||
          emp['statusText']?.includes('עבר בהצלחה') && this.filterStatusHolder.pass
        )
      )
    }
    this.employeeService.getEmployeeList().pipe(first(list => list.length > 0)).subscribe(list => {
      this.filterHolder['site'].valuesToChoose = list.map(emp => emp.site ? emp.site.trim() : '');
      this.filterHolder['department'].valuesToChoose = list.map(emp => emp.department ? emp.department.trim() : '');
      this.filterHolder['role'].valuesToChoose = list.map(emp => emp.role ? emp.role.trim() : '');
      this.filterHolder['languages'].valuesToChoose = [];
      for (let emp of list){
        if (emp.languages){
          this.filterHolder['languages'].valuesToChoose = this.filterHolder['languages'].valuesToChoose.concat(emp.languages);
        }
      }
      for (let prop of Object.keys(this.filterHolder)){
        this.filterHolder[prop].valuesToChoose.push('');
        this.filterHolder[prop].valuesToChoose = removeDuplicates(this.filterHolder[prop].valuesToChoose);
        this.filterHolder[prop].valuesToChoose.sort((s1,s2) => s1.localeCompare(s2));
      }
      this.filterHolder['languages'].valuesToChoose.sort((s1,s2) => languages.findIndex(l => l.code == s1) - languages.findIndex(l => l.code == s2));
    });
    this.courses$ = this.courseService.getCourseList();
    this.assigns$ = this.assignService.getCourseAssign();
    this.allEmployees$ = new BehaviorSubject([]);
    this.assignService.getEmployees().subscribe(list => this.allEmployees$.next(list));
    this.employees$ = this.allEmployees$
      .pipe(
        filterByProp('site'),
        filterByProp('department'),
        filterByProp('role'),
        filterByProp('languages'),
        map(list => list.filter(emp => 
          !this.searchValue 
            ||
          (
            emp.number?.includes(this.searchValue.trim())
            || 
            emp.firstName?.includes(this.searchValue.trim())
            || 
            emp.surname?.includes(this.searchValue.trim())
            || 
            emp.identity?.includes(this.searchValue.trim())
            || 
            emp.phone?.includes(this.searchValue.trim())
            ||
            emp.email?.includes(this.searchValue.trim())
            ||
            combineFirstAndSurname(emp.firstName, emp.surname)?.includes(this.searchValue.trim())
          )
        )),
        filterByStatus()
      );
    
    this.employees$.subscribe(list => this.tempLastEmployees = list);
    
    this.sub = this.route.params.subscribe(params => {
      if (params['c_id']){
        let id = +params['c_id'];
        this.courses$.pipe(take(1),map(list => list.find(c => c.id == id))).subscribe(course => {
          if (course){
            this.selectCourse(course);
          } else {
            this.selectCourse(null)
            this.router.navigate(['/assign']);
          }
        });
      } else {
        this.selectCourse(null)
      }
   });
    this.holder = this.assignService.dirtyListHolder;
  }

  ngOnDestroy(): void{
    this.sub.unsubscribe();
  }

  filterClick(key:string,val:string){
    if (this.filterHolder[key]){
      this.filterHolder[key].valueToFilter = val;
      this.updateEmployeeFilter()
      let temp = this.filterHolder[key].valuesToChoose;
      this.filterHolder[key].valuesToChoose = [];
      window.setTimeout(() => {this.filterHolder[key].valuesToChoose = temp},100);
      this.removeFromDOM = true;
      window.setTimeout(() => this.removeFromDOM = false,200);
    }
  }

  updateEmployeeFilter(){
    this.allEmployees$.next(this.allEmployees$.value);
  }

  selectCourse(course:Course): void {
    for (let key of Object.keys(this.filterHolder)){
      this.filterHolder[key].valueToFilter = '';
      this.updateEmployeeFilter();
    }
    if (course){
      this.courseService.selectedCourse = {course:course, lang: null};
      this.assignService.setCourse(course);
      this.historyService.setCourse(course);
      if (course.languages.length > 0){
        this.selectLanguage(course.languages[0]);
      }
    } else {
      this.courseService.selectedCourse = null;
    }
  }
  isCourseSelected(course:Course){
    if (!course){
      return !!this.courseService.selectedCourse
    }
    return this.courseService.selectedCourse?.course === course
  }
  getLanguages(){
    return this.courseService.selectedCourse?.course.languages
  }
  selectLanguage(lang:string){
    if (this.courseService.selectedCourse){
      this.courseService.selectedCourse.lang = lang;
    }
  }
  isLangSelected(lang?:string){
    if (!lang){
      return !!this.courseService.selectedCourse?.lang
    }
    return this.courseService.selectedCourse?.lang === lang
  }
  getLangHebrewTitle = getLangHebrewTitle;
  isSelected(emp:Employee){
    return this.selectedEmployees.includes(emp);
  }
  selectClick(emp:Employee){
    if (this.isSelected(emp)){
      this.selectedEmployees = this.selectedEmployees.filter(e => e !== emp);
    } else {
      this.selectedEmployees.push(emp);
      if (!this.assignService.dirtyListHolder.dirty.find(ass => ass.employee == emp.id)){
        let assign = new Assign();
        assign.employee = emp.id;
        assign['name'] = emp.firstName + ' ' +emp.surname;
        this.assignService.dirtyListHolder.dirty.push(assign);
      }
    }
  }
  selectAll(bool:boolean){
    if (bool){
        for (let emp of this.tempLastEmployees){
          if (!this.selectedEmployees.find(e => e.id == emp.id)){
            if (!this.isSelected(emp)){
              this.selectClick(emp);
            }
          }
        }
    } else {
      this.selectedEmployees = [];
    }
  }
  isAllSelected(bool:boolean):boolean{
    if (bool){
      for (let emp of this.tempLastEmployees){
        if (!this.selectedEmployees.find(e => e.id == emp.id))
          return false;
      }
      return true;
    } else {
      return this.selectedEmployees.length == 0;
    }
  }
  removeAssign(assign:Assign){
    this.assignService.dirtyListHolder.dirty = this.assignService.dirtyListHolder.dirty.filter(e => e.employee !== assign.employee);
    this.selectedEmployees = this.selectedEmployees.filter(e => e.id !== assign.employee);
  }
  save(bool?:boolean){
    this.assignService.saveDirty(bool).then(() => {
      // this.router.navigate(['/assign',this.route.snapshot.params['c_id']]);
      this.selectedEmployees = [];
    })
  }
  setStatusSelected(val:'pass'|'notPass'|'sent'|'notSent'):void {
    if (this.filterStatusHolder[val]){
      delete this.filterStatusHolder[val];
    } else {
      this.filterStatusHolder[val] = true;
    }
    this.updateEmployeeFilter();
  }
  isStatusSelected(val:'pass'|'notPass'|'sent'|'notSent'):boolean {
    return !!this.filterStatusHolder[val]
  }
  openStatusFilter(obj:{open:boolean,hover:boolean}){
    if (!obj.open){
      window.setTimeout(() => {
        if (obj.open && !obj.hover){
          obj.open = false;
        }
      },3000)
    }
    obj.open = !obj.open;
  }
  exportList(){
    // let componentToHex = (c) => {
    //   var hex = c.toString(16);
    //   return hex.length == 1 ? "0" + hex : hex;
    // }
    
    // let rgbToHex = (val:string) => {
    //   if (!val) return undefined;
    //   let rgb = val.slice(val.indexOf('(')+1,val.indexOf(')')).split(',');
    //   let r = rgb[0];
    //   let g = rgb[1];
    //   let b = rgb[2];
    //   return /*"#" + */componentToHex(r) + componentToHex(g) + componentToHex(b);
    // }
    let workbook = new ExcelJS.Workbook();
    workbook.creator = 'Momenteken Quality & Safety Engineering Ltd';
    let sheet = workbook.addWorksheet('גיליון 1', { views: [{ state: 'frozen', ySplit: 3, rightToLeft: true }] });
    sheet.mergeCells('A1', 'J2');
    sheet.getCell('A1').style.fill = {type: 'pattern', pattern: 'solid', fgColor: { theme: 4 } };
    sheet.getCell('A1').style.font = {color: { argb: 'FFFFFF' }, size: 16, bold: true };
    sheet.getCell('A1').style.alignment = { horizontal: 'center', vertical: 'middle' };
    sheet.getRow(3).values = ['מספר עובד','שם פרטי','שם משפחה','תעודת זהות','טלפון נייד','דוא\"ל','סניף','מחלקה','תפקיד','סטטוס'];
    sheet.columns = [
        { header: 'מספר עובד', key: 'employee_number', width: 8.8 } as ExcelJS.Column,
        { header: 'שם פרטי', key: 'pname', width: 12.1 } as ExcelJS.Column,
        { header: 'שם משפחה', key: 'lname', width: 12.9 } as ExcelJS.Column,
        { header: 'תעודת זהות', key: 'employee_identification', width: 16.8 } as ExcelJS.Column,
        { header: 'טלפון נייד', key: 'phone_number', width: 16.2 } as ExcelJS.Column,
        { header: 'דוא\"ל', key: 'email', width: 20.4 } as ExcelJS.Column,
        { header: 'סניף', key: 'employee_company', width: 14.4 } as ExcelJS.Column,
        { header: 'מחלקה', key: 'department', width: 15.4 } as ExcelJS.Column,
        { header: 'תפקיד', key: 'role', width: 12.8 } as ExcelJS.Column,
        { header: 'סטטוס', key: 'status', width: 15 } as ExcelJS.Column,
    ];
    sheet.getCell('A1').value = 'לומדה - '+this.courseService.selectedCourse.course.title

    sheet.getCell('A3').style.fill = { type: 'pattern', pattern: 'solid', fgColor: { theme: 2 } };
    sheet.getCell('B3').style.fill = { type: 'pattern', pattern: 'solid', fgColor: { theme: 2 } };
    sheet.getCell('C3').style.fill = { type: 'pattern', pattern: 'solid', fgColor: { theme: 2 } };
    sheet.getCell('D3').style.fill = { type: 'pattern', pattern: 'solid', fgColor: { theme: 2 } };
    sheet.getCell('E3').style.fill = { type: 'pattern', pattern: 'solid', fgColor: { theme: 2 } };
    sheet.getCell('F3').style.fill = { type: 'pattern', pattern: 'solid', fgColor: { theme: 2 } };
    sheet.getCell('G3').style.fill = { type: 'pattern', pattern: 'solid', fgColor: { theme: 2 } };
    sheet.getCell('H3').style.fill = { type: 'pattern', pattern: 'solid', fgColor: { theme: 2 } };
    sheet.getCell('I3').style.fill = { type: 'pattern', pattern: 'solid', fgColor: { theme: 2 } };
    sheet.getCell('J3').style.fill = { type: 'pattern', pattern: 'solid', fgColor: { theme: 2 } };
    for (let emp of this.tempLastEmployees) {
        let row = sheet.addRow({
            employee_number: emp.number,
            pname: emp.firstName,
            lname: emp.surname,
            employee_identification: emp.identity,
            phone_number: emp.phone,
            email: emp.email,
            employee_company: emp.site,
            department: emp.department,
            role: emp.role,
            status: emp.statusText
        });
        if (emp.statusColor){
          row.getCell('J').style.fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { theme: emp.statusText.includes('בהצלחה') ? 6 : 9 }//'FF'+rgbToHex(emp.statusColor)
        }
      }
    }
    
    workbook.xlsx.writeBuffer().then((data: any) => {
      console.log("buffer");
      const blob = new Blob([data], {
        type:
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
      });
      let url = window.URL.createObjectURL(blob);
      let a = document.createElement("a");
      document.body.appendChild(a);
      a.setAttribute("style", "display: none");
      a.href = url;
      a.download = this.courseService.selectedCourse.course.title+' - סטטוס - '+(new DatePipe('he')).transform(new Date(),'dd.MM.yy')+".xlsx";
      a.click();
      window.URL.revokeObjectURL(url);
      a.remove();
    });
    // const wbout = workbook.xlsx.writeBuffer();

    // function s2ab(s) {
    //   const buf = new ArrayBuffer(s.length);
    //   const view = new Uint8Array(buf);
    //   for (let i = 0; i !== s.length; ++i) {
    //     view[i] = s.charCodeAt(i) & 0xFF;
    //   };
    //   return buf;
    // }
    
    // saveAs(new Blob([s2ab(wbout)], { type: 'application/octet-stream' }), 'סטטוס עובדים - '+this.courseService.selectedCourse.course.title+'.xlsx');
  }
}
