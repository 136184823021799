<div class="icon">
    <img src="assets/bluewy.svg">
    <div class="inner-icon">
        <img src="assets/calendar.png">
    </div>
</div>

<div class="course-section">
    <span class="title">בחר לומדה</span>
    <div class="course-list-wrapper">
        <div *ngFor="let course of courses$ | async" class="course-btn" [routerLink]="['/assign',course.id+'']" [ngClass]="{'selected':isCourseSelected(course)}">
            {{course.title}}
        </div>
    </div>
    <span class="lang-title">בחר שפה</span>
    <div class="lang-wrapper" *ngIf="getLanguages(); let languages">
        <div class="single-lang" *ngFor="let lang of languages">
            <span class="checkmark" (click)="selectLanguage(lang)">
                <span class="checkdot" *ngIf="isLangSelected(lang)"></span>
            </span>
            <span>{{getLangHebrewTitle(lang)}}</span>
        </div>
    </div>
</div>

<div class="assign-section">
    <div class="wrapper" *ngIf="isLangSelected()">
        <span class="title">זימון עובדים להדרכה</span>
        <div class="filters-row">
            <div class="single-filter">
                <div class="filter-title"><span>סניף</span></div>
                <div class="filter-box"><span style="overflow: hidden;" [attr.title]="filterHolder['site'].valueToFilter">{{filterHolder['site'].valueToFilter}}</span></div>
                <div #optionsWrapper class="options-wrapper" *ngIf="!removeFromDOM">
                    <div class="filter-box filter-option" *ngFor="let val of filterHolder['site'].valuesToChoose" [ngClass]="{'clean': !val }" (click)="filterClick('site',val)">{{ val ? val : 'נקה בחירה' }}</div>
                </div>
            </div>
            <div class="single-filter">
                <div class="filter-title"><span>מחלקה</span></div>
                <div class="filter-box"><span style="overflow: hidden;" [attr.title]="filterHolder['department'].valueToFilter">{{filterHolder['department'].valueToFilter}}</span></div>
                <div #optionsWrapper class="options-wrapper" *ngIf="!removeFromDOM">
                    <div class="filter-box filter-option" *ngFor="let val of filterHolder['department'].valuesToChoose" [ngClass]="{'clean': !val }" (click)="filterClick('department',val)">{{ val ? val : 'נקה בחירה' }}</div>
                </div>
            </div>
            <div class="single-filter">
                <div class="filter-title"><span>תפקיד</span></div>
                <div class="filter-box"><span style="overflow: hidden;" [attr.title]="filterHolder['role'].valueToFilter">{{filterHolder['role'].valueToFilter}}</span></div>
                <div #optionsWrapper class="options-wrapper" *ngIf="!removeFromDOM">
                    <div class="filter-box filter-option" *ngFor="let val of filterHolder['role'].valuesToChoose" [ngClass]="{'clean': !val }" (click)="filterClick('role',val)">{{ val ? val : 'נקה בחירה' }}</div>
                </div>
            </div>
            <div class="single-filter">
                <div class="filter-title"><span>שפה</span></div>
                <div class="filter-box">{{ getLangHebrewTitle(filterHolder['languages'].valueToFilter) }}</div>
                <div #optionsWrapper class="options-wrapper">
                    <div class="filter-box filter-option" *ngFor="let val of filterHolder['languages'].valuesToChoose" [ngClass]="{'clean': !val }" (click)="filterClick('languages',val)">{{ val ? getLangHebrewTitle(val) : 'נקה בחירה' }}</div>
                </div>
            </div>
            <div class="single-filter">
                <div class="filter-title"><span>חפש</span></div>
                <input class="filter-box" [(ngModel)]="searchValue" (keyup)="updateEmployeeFilter()">
            </div>
        </div>
        <div class="another-section">

            <div class="list-section">

                <span class="sub-title">לחץ על העיגולים לבחירת עובדים</span>
                <div class="head-row">
                    <div class="head-row-rect align-center" style="width: 40px;"></div>
                    <div class="head-row-rect" style="width: 110px;">
                        <span>שם</span>
                    </div>
                    <div class="head-row-rect" style="width: 115px;">
                        <span>משפחה</span>
                    </div>
                    <div class="head-row-rect" style="width: 110px;">
                        <span>תעודת זהות</span>
                    </div>
                    <div class="head-row-rect status-width" *ngIf="{open:false}; let obj">
                        <span>סטטוס</span>
                        <img src="assets/filter-arrow.png" (click)="openStatusFilter(obj)">
                        <div class="to-shrink" [ngClass]="{'grow': obj.open}">
                            <div class="list" (mouseenter)="obj.hover = true" (mouseleave)="obj.open = obj.hover = false">
                                <div class="status-filter green" (click)="setStatusSelected('pass')" [ngClass]="{'selected':isStatusSelected('pass')}">
                                    <span class="checkmark">
                                        <span class="checkdot" *ngIf="isStatusSelected('pass')"></span>
                                    </span>
                                    <span>עבר בהצלחה</span>
                                </div>
                                <div class="status-filter orange" (click)="setStatusSelected('notPass')" [ngClass]="{'selected':isStatusSelected('notPass')}">
                                    <span class="checkmark">
                                        <span class="checkdot" *ngIf="isStatusSelected('notPass')"></span>
                                    </span>
                                    <span>לא עבר</span>
                                </div>
                                <div class="status-filter blue" (click)="setStatusSelected('sent')" [ngClass]="{'selected':isStatusSelected('sent')}">
                                    <span class="checkmark">
                                        <span class="checkdot" *ngIf="isStatusSelected('sent')"></span>
                                    </span>
                                    <span>נשלח</span>
                                </div>
                                <div class="status-filter blue" (click)="setStatusSelected('notSent')" [ngClass]="{'selected':isStatusSelected('notSent')}">
                                    <span class="checkmark">
                                        <span class="checkdot" *ngIf="isStatusSelected('notSent')"></span>
                                    </span>
                                    <span>לא נשלח</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="rows-wrapper-wrapper">
                    <div class="rows-wrapper hide-scrollbar">
                        <div *ngFor="let emp of employees$ | async" class="single-row" (click)="selectClick(emp)">
                            <div style="width: 40px;" class="checkwrap align-center" [ngClass]="{'selected':isSelected(emp)}">
                                <span class="checkmark2" style="margin: 0;" [ngStyle]="{ 'backgroundColor' : isSelected(emp) ? 'rgba(22, 159, 178, 1)' : undefined }"></span>
                            </div>
                            <div class="row-detail-wrapper">
                                <div style="width: 110px;">
                                    <span [attr.title]="emp.firstName">{{emp.firstName}}</span>
                                </div>
                                <div style="width: 115px;">
                                    <span [attr.title]="emp.surname">{{emp.surname}}</span>
                                </div>
                                <div style="width: 110px;">
                                    <span [attr.title]="emp.identity">{{emp.identity}}</span>
                                </div>
                            </div>
                            <div class="status-wrap align-center status-width" [ngStyle]="{'backgroundColor': emp.statusColor }">
                                {{emp.statusText}}
                            </div>
                        </div>
                    </div>
                </div>
                <div class="chooser-wrapper">
                    <div class="chooser">
                        <span class="checkmark" (click)="selectAll(true)">
                            <span class="checkdot" *ngIf="isAllSelected(true)"></span>
                        </span>
                        <span>בחר הכל</span>
                    </div>
                    <div class="chooser" style="flex: 1;">
                        <span class="checkmark" (click)="selectAll(false)">
                            <span class="checkdot" *ngIf="isAllSelected(false)"></span>
                        </span>
                        <span>נקה את כל הבחירות</span>
                    </div>
                    <div class="xlsx-btn" (click)="exportList()">
                        <span>ייצוא</span>
                        <img src="assets/xlsx.png">
                    </div>
                </div>

            </div>

            <div class="right-section">
                <div class="assigns-section" *ngIf="holder.dirty; let list">
                    <div class="head">
                        <span>נבחרו</span>
                        <span style="padding: 0 10px; font-weight: 600; font-size: 30px;">{{list.length}}</span>
                        <span>עובדים</span>
                    </div>
                    <div class="list-wrapper">
                        <div *ngFor="let ass of list" class="assign-row">
                            <span class="x" (click)="removeAssign(ass)">x</span>
                            <span class="name">{{ass.name}}</span>
                        </div>
                    </div>
                </div>
                <div class="btns-wrapper">

                    <div class="save-btn" (click)="save(false)">
                        <span>שמירה</span>
                    </div>
                    <div class="send-btn" (click)="save(true)">
                        <span>שלח זימון</span>
                    </div>
                </div>
            </div>

        </div>
    </div>
</div>