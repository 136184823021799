<div class="icon">
    <img src="assets/bluewy.svg">
    <div class="inner-icon">
        <img src="assets/header-avatar.png">
    </div>
</div>

<div class="right-section">
    <div class="title">כרטיס עובד</div>
    <div class="content">
        <div class="input-wrapper">
            <div class="input-title"><span>מספר עובד</span></div>
            <input [(ngModel)]="holder.dirty.number">
        </div>
        <div class="input-wrapper">
            <div class="input-title"><span>שם פרטי</span></div>
            <input [(ngModel)]="holder.dirty.firstName">
        </div>
        <div class="input-wrapper">
            <div class="input-title"><span>שם משפחה</span></div>
            <input [(ngModel)]="holder.dirty.surname">
        </div>
        <div class="input-wrapper">
            <div class="input-title"><span>תעודת זהות</span></div>
            <input [(ngModel)]="holder.dirty.identity">
        </div>
        <div class="input-wrapper">
            <div class="input-title"><span>טלפון נייד</span></div>
            <input [(ngModel)]="holder.dirty.phone">
        </div>
        <div class="input-wrapper">
            <div class="input-title"><span>כתובת דוא&quot;ל</span></div>
            <input [(ngModel)]="holder.dirty.email">
        </div>
        <div class="input-wrapper">
            <div class="input-title"><span>שליטה בשפות</span></div>
            <div class="lang-wrapper">
                <div class="single-lang" *ngFor="let lang of languages">
                    <span class="checkmark" (click)="clickOnLang(lang.code)">
                        <span class="checkdot" *ngIf="holder.dirty?.languages?.indexOf(lang.code) >= 0"></span>
                    </span>
                    <span>{{lang.title}}</span>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="left-section">
    <div class="title">מקום עבודה</div>
    <div class="content">
        <div class="input-wrapper">
            <div class="input-title"><span>סניף</span></div>
            <input [(ngModel)]="holder.dirty.site">
        </div>
        <div class="input-wrapper" *ngIf="{}; let obj">
            <div class="input-title"><span>מחלקה</span></div>
            <input [(ngModel)]="holder.dirty.department" (focus)="obj.focus = true" (blur)="obj.focus = false">
            <div class="autocomplete" *ngIf="departmentFiltered()?.length > 0 && (obj.focus || obj.hover)" (mouseenter)="obj.hover = true" (mouseleave)="obj.hover = false">
                <div *ngFor="let department of departmentFiltered()" (click)="setDepartment(department);obj.hover = false;">
                    {{department}}
                </div>
            </div>
        </div>
        <div class="input-wrapper" *ngIf="{}; let obj">
            <div class="input-title"><span>תפקיד</span></div>
            <input [(ngModel)]="holder.dirty.role" (focus)="obj.focus = true" (blur)="obj.focus = false">
            <div class="autocomplete" *ngIf="roleFiltered()?.length > 0 && (obj.focus || obj.hover)" (mouseenter)="obj.hover = true" (mouseleave)="obj.hover = false">
                <div *ngFor="let role of roleFiltered()" (click)="setRole(role);obj.hover = false;">
                    {{role}}
                </div>
            </div>
        </div>
        <div class="input-wrapper">
            <div class="input-title"><span>הערות</span></div>
            <!-- <input [(ngModel)]="holder.dirty.comments"> -->
            <textarea [(ngModel)]="holder.dirty.comments"></textarea>
        </div>
        <div class="btns-wrapper">
            <div class="archive-btn" (click)="archiveClick()" *ngIf="(showArchiveBtn$ | async)">
                <span>שליחה לארכיון</span>
                <img src="assets/archive.png">
            </div>
            <div class="save-btn" (click)="saveClick()">
                <span>שמירה</span>
            </div>
        </div>
    </div>
</div>