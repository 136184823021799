import { Course } from '../course/course.model';
import { Employee } from '../employee/employee.model';
import { History } from './history.model';

export interface HistoryState {
    employee: {
        subject: Employee,
        map: {
            [key:number]: History[]
        }
    },
    course: {
        subject: Course,
        map: {
            [key:number]: History[]
        }
    }
}

export const initialHistoryState: HistoryState = {
    employee: {
        subject: null,
        map: {}
    },
    course: {
        subject: null,
        map: {}
    }
}