import { Action } from '@ngrx/store';
import { Employee } from './employee.model';

export enum EmployeeAction {
    AddEmployee = '[Employee] add a new employee',
    EditEmployee = '[Employee] edit employee details',
    SetArchive = '[Employee] makes employee status inactive',
    SetActive = '[Employee] makes employee status active',
    SetList = '[Employee] loads new employees list'
}

export class AddEmployee implements Action {
    public readonly type = EmployeeAction.AddEmployee;
    constructor(public payload: Employee) {}
}
export class EditEmployee implements Action {
    public readonly type = EmployeeAction.EditEmployee;
    constructor(public payload: Employee) {}
}
export class SetArchive implements Action {
    public readonly type = EmployeeAction.SetArchive;
    constructor(public payload: {id: number, leavingDate: Date}){}
}
export class SetActive implements Action {
    public readonly type = EmployeeAction.SetActive;
    constructor(public payload: number) {}
}
export class SetList implements Action {
    public readonly type = EmployeeAction.SetList;
    constructor(public payload: Employee[]) {}
}

export type EmployeeActions = AddEmployee | EditEmployee | SetArchive | SetActive | SetList;
// export const add = createAction('add a new employee', props<{employee: Employee}>());
// export const edit = createAction('edit employee details', props<{employee: Employee}>());
// export const archive = createAction('makes employee status inactive', props<{id: number, leavingDate: Date}>());
// export const active = createAction('makes employee status active', props<{id: number}>());
// export const list = createAction('loads new employees list', props<{employees: Employee[]}>());