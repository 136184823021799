import { createSelector } from '@ngrx/store';
import { AppState } from '../app/app.state';
import { EmployeeState } from './employee.state';

const selectEmployees = (state:AppState) => state.employee;

export const selectEmployeeList = createSelector(
    selectEmployees,
    (state:EmployeeState) => state.list.filter(e => !e.leavingDate).sort((e1,e2) => e1.firstName.localeCompare(e2.firstName))
);
export const selectEmployeeArchiveList = createSelector(
    selectEmployees,
    (state:EmployeeState) => state.list.filter(e => !!e.leavingDate).sort((e1,e2) => e1.firstName.localeCompare(e2.firstName))
);