<audio *ngIf="openingAudioUrl" controls autoplay style="display:none;">
    <source [src]="openingAudioUrl" type="audio/mpeg">
</audio>

<img class="logo" style="margin-bottom: 10px;" [src]="clientLogoUrl">

<div style="text-align: center; color: #1E3E93; font-size: 38px; font-weight: 400;direction: rtl; padding: 15px 0;">{{whatTypeOfTruckDoYouDrive}}</div>

<div class="truck-btn" (click)="selectedTruckType = Truck.Pallet" [ngClass]="{'clicked': selectedTruckType === Truck.Pallet }">
    <audio *ngIf="selectedTruckType === Truck.Pallet" controls autoplay style="display:none;">
        <source [src]="palletAudioUrl" type="audio/mpeg">
    </audio>
    <div class="img-wrapper">
        <img src="assets/pallet-truck.png">
    </div>
    <div class="title">{{truckTitle(Truck.Pallet)}}</div>
</div>
<div class="truck-btn" (click)="selectedTruckType = Truck.Tanker" [ngClass]="{'clicked': selectedTruckType === Truck.Tanker }">
    <audio *ngIf="selectedTruckType === Truck.Tanker" controls autoplay style="display:none;">
        <source [src]="tankerAudioUrl" type="audio/mpeg">
    </audio>
    <div class="img-wrapper">
        <img src="assets/tanker.png">
    </div>
    <div class="title">{{truckTitle(Truck.Tanker)}}</div>
</div>
<div class="truck-btn" (click)="selectedTruckType = Truck.Trailer" [ngClass]="{'clicked': selectedTruckType === Truck.Trailer }">
    <audio *ngIf="selectedTruckType === Truck.Trailer" controls autoplay style="display:none;">
        <source [src]="trailerAudioUrl" type="audio/mpeg">
    </audio>
    <div class="img-wrapper">
        <img src="assets/trailer.png">
    </div>
    <div class="title">{{truckTitle(Truck.Trailer)}}</div>
</div>

<div class="to-fill-width" style="min-height: 70px;" *ngIf="selectedTruckType == Truck.Tanker">
    <div class="attachment">
        <div class="attach-text">{{approvalOfworkInHeightShouldBeAtteched}}</div>
        <div class="attach-btns-wrapper">
            <div class="attach-icon-wrapper" (click)="fileInput1.click()">
                <img class="attach-icon" src="/assets/camera.png">
                <input style="display: none" type="file" accept="image/*" capture="camera" #fileInput1 (change)="uploadEducationImage($event)">
            </div>
            <div class="attach-icon-wrapper" (click)="fileInput2.click()">
                <img class="attach-icon" src="/assets/clip2.png">
                <input style="display: none" type="file" accept="image/*" #fileInput2 (change)="uploadEducationImage($event)">
                <!--,application/pdf-->
            </div>
        </div>
    </div>
</div>
<div class="to-fill-width">
    <div class="button3" (click)="startSession()">{{nextBtnText}}</div>
</div>