import { state } from '@angular/animations';
import {ActionReducer, Action, State, createReducer, on} from '@ngrx/store';
// import { active, add, archive, edit, list } from './employee.actions';
import { EmployeeActions, EmployeeAction } from './employee.actions';
import { EmployeeState, initialEmployeeState } from './employee.state';

export const employeeReducer = (
    state = initialEmployeeState,
    action: EmployeeActions
): EmployeeState => {
    switch (action.type) {
        case EmployeeAction.SetList: {
            return {
                ...state,
                list: action.payload
            };
        }
        case EmployeeAction.AddEmployee: {
            return {
                ...state,
                list: [ ...state.list , action.payload ]
            };
        }
        case EmployeeAction.EditEmployee: {
            return {
                ...state,
                list: state.list.map(e => e.id === action.payload.id ? { ...e, ...action.payload } : e )
            };
        }
        case EmployeeAction.SetArchive: {
            return {
                ...state,
                list: state.list.map(e => e.id === action.payload.id ? { ...e, leavingDate: action.payload.leavingDate } : e )
            };
        }
        case EmployeeAction.SetActive: {
            return {
                ...state,
                list: state.list.map(e => e.id === action.payload ? { ...e, leavingDate: null } : e )
            };
        }
        default: {
            return state;
        }
    }
}

// export const initialState = {
//     employeeList: []
// };

// const _employeeReducer = createReducer(
//     initialState,
//     on(list, (state, { employees } ) => employees ? { ...state, employeeList: [ ...employees ] } : { ...state, employees : null }),
//     on(add, (state, { employee }) => {
//         let list = [ ...state.employeeList , employee ];
//         return {...state, employeeList: list };
//     }),
//     on(edit, (state, { employee } ) => {
//         let list = state.employeeList.map(e => e.id === employee.id ? { ...e, ...employee } : e );
//         return {...state, employeeList: list };
//     }),
//     on(archive, (state, { id, leavingDate } ) => {
//         let list = state.employeeList.map(e => e.id === id ? { ...e, leavingDate: leavingDate } : e );
//         return {...state, employeeList: list };
//     }),
//     on(active, (state, { id } ) => {
//         let list = state.employeeList.map(e => e.id === id ? { ...e, leavingDate: null } : e );
//         return {...state, employeeList: list };
//     }),
// );

// export function employeeReducer(state, action) {
//     return _employeeReducer(state, action);
// }