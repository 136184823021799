<div class="main-wrapper">
    <div class="playground-wrapper">
        <div class="playground">


            <div class="icon">
                <img src="assets/bluewy.svg">
                <div class="inner-icon">
                    <img src="assets/suitcase.svg">
                </div>
            </div>

            <div class="main-section">

                <div class="courses-section">
                    <div class="title">בחר לומדה</div>
                    <div class="course-list-wrapper">
                        <div *ngFor="let course of courses$ | async" class="course-btn" (click)="selectCourse(course)" [ngClass]="{'selected':isCourseSelected(course)}">
                            {{course.title}}
                        </div>
                    </div>
                </div>

                <div class="language-n-button-section">
                    <div class="languages">
                        <div class="title">בחר שפה</div>
                        <div class="lang-wrapper" *ngIf="getLanguages(); let languages">
                            <div class="single-lang" *ngFor="let lang of languages">
                                <span class="checkmark" (click)="selectLanguage(lang)">
                                    <span class="checkdot" *ngIf="isLangSelected(lang)"></span>
                                </span>
                                <span>{{getLangHebrewTitle(lang)}}</span>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="isCourseSelected()" class="button" (click)="startCourse()">
                        <span>התחל</span>
                    </div>
                </div>

            </div>

            <div class="side-section">
                <img src="assets/person.png">
            </div>



        </div>
    </div>
</div>