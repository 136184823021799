import { ActionReducerMap } from '@ngrx/store';
import { assignReducer } from '../assign/assign.reducer';
import { courseReducer } from '../course/course.reducer';
import { employeeReducer } from '../employee/employee.reducer';
import { historyReducer } from '../history/history.reducer';
import { AppState } from './app.state';

export const appReducres: ActionReducerMap<AppState, any> = {
    employee: employeeReducer,
    course: courseReducer,
    assign: assignReducer,
    history: historyReducer
};