import { Action } from '@ngrx/store';
import { Course } from '../course/course.model';
import { Employee } from '../employee/employee.model';
import { History } from './history.model';

export enum HistoryAction {
    SetCourseHistory = '[History] loads course history list',
    SetEmployeeHistory = '[History] loads employee history list'
}
export class SetCourseHistory implements Action {
    public readonly type = HistoryAction.SetCourseHistory;
    constructor(public payload: {course: Course, list: History[]}) {}
}
export class SetEmployeeHistory implements Action {
    public readonly type = HistoryAction.SetEmployeeHistory;
    constructor(public payload:  {employee: Employee, list: History[]}) {}
}
export type HistoryActions = SetCourseHistory | SetEmployeeHistory;