import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { map, take } from 'rxjs/operators';
import { UserService } from '../../user-service/user.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  username:string = '';
  password:string = '';
  constructor(private userService: UserService, private router: Router, private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.route.data.subscribe(data => {
      if (data.logout){
        this.userService.logout();
        this.router.navigate(['/login']);
      }
    });
    this.userService.isLogin$.pipe(take(1),map(obj => obj.val)).subscribe(isIt => {
      if (isIt){
        this.getUserDataAndNavigate();
      }
    })
    // if (this.userService.isLogin$.){
    //   this.userService.getUserData();
    //   this.router.navigate(['/']);
    // } else if (this.userService.isLoginForCourses()){
    //   this.userService.getUserData();
    //   this.router.navigate(['/access/courses']);
    // }
  }

  private getUserDataAndNavigate(){
    this.userService.getUserData();
    if (this.userService.isLogin$.value.access){
      this.router.navigate(['/access/courses']);
    } else {
      this.router.navigate(['/']);
    }
  }

  onClick(){
    if (this.username && this.username.length > 0 && this.password && this.password.length > 0){
      this.userService.login(this.username,this.password).subscribe(val => {
        if (val){
          this.getUserDataAndNavigate();
          // if (this.userService.isLogin()){
          //   this.userService.getUserData();
          //   this.router.navigate(['/']);
          // } else if (this.userService.isLoginForCourses()){
          //   this.userService.getUserData();
          //   this.router.navigate(['/access/courses']);
          // }
        } else {
          alert('Error: N.S.T.H.');
        }
      }, err => alert(err));
    }
  }

}
