import { Action, createAction, props } from '@ngrx/store';
import { Course } from './course.model';

export enum CourseAction {
    SetList = '[Employee] loads new corse list'
}

export class SetList implements Action {
    public readonly type = CourseAction.SetList;
    constructor(public payload: Course[]) {}
}

export type CourseActions = SetList;
// export const list = createAction('loads new course list', props<{courses: Course[]}>());