import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router, CanLoad, Route, UrlSegment } from '@angular/router';
import { Observable } from 'rxjs';
import { UserService } from './user-service/user.service';
import { LoginComponent } from './login-module/login/login.component'

@Injectable({
  providedIn: 'root'
})
export class LoggedInGuard implements CanLoad, CanActivate {
  lastRedirectToLogin:Date = new Date(0);//so when loading app for the first time it will redirect to logout->login / main-module (if logged-in)
  constructor(private router: Router, private userService: UserService){}
  canLoad(route: Route, segments: UrlSegment[]): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
    return this.canProcced(route);
  }
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.canProcced(route);
  }
  canProcced(route: Route|ActivatedRouteSnapshot){
    if (this.userService.isLogin$.value.val && !this.userService.isLogin$.value.access){
      if (route.component instanceof LoginComponent){
        return this.router.createUrlTree(['/']);
      }
      return true;
    }
    if (this.userService.isLogin$.value.val && this.userService.isLogin$.value.access === 'courses'){
      if (route.component instanceof LoginComponent){
        return this.router.createUrlTree(['/access/courses']);
      }
      return true;
    }
    if (Date.now() - this.lastRedirectToLogin.getTime() > 1000){
      this.lastRedirectToLogin = new Date();
      return this.router.createUrlTree(['/logout']);
    }
    return false;
  }
}
