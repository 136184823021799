import { HttpClient, HttpHeaders, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of, Subject } from 'rxjs';
import { catchError, map, take } from 'rxjs/operators';
import { WebSocketSubject } from 'rxjs/webSocket/index';
import { Assign } from '../stores/assign/assign.model';
import { Course } from '../stores/course/course.model';
import { Employee } from '../stores/employee/employee.model';
import { History } from '../stores/history/history.model';

export const SKIP_BLOCK_UI:string = 'Skip-Block-UI';
export const languages = [
  {code:'he',title:'עברית'},
  {code:'en',title:'אנגלית'},
  {code:'ar',title:'ערבית'},
  {code:'ru',title:'רוסית'},
  {code:'am',title:'אמהרית'},
  {code:'ot',title:'אחר'}
];
export const getLangHebrewTitle = (lang:string) => {
  return languages.find(l => l.code == lang)?.title;
}

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  resourceExist(resourceUrl: string) {
      return this.http.get(resourceUrl, {withCredentials: true, responseType: 'arraybuffer'});
  }
  public readonly ROOT_URL = 'https://panel.momenteken-college.co.il';//'http://localhost:8994';//
  readonly API_URL = 'https://panel.momenteken-college.co.il/api';
  // readonly WS_URL = 'wss://panel.momenteken-college.co.il/';
  constructor(private http: HttpClient) {
    
  }
  setIdenToAccess(url:string,idenValue:string){
    let rtn:Subject<string> = new Subject();
    this.http.post(url,{iden:idenValue},{ withCredentials: true, observe: 'response', responseType: 'text' })
      .subscribe(res => {
        if (res.status == 302)
          rtn.next(res.headers.get('Location'));
        else if (res.status == 200)
          rtn.next(res.body);//window.open(res.body,'_self');
        else
          rtn.next(null);
      }, err => {rtn.error(err)});
    return rtn;
  }
  login(username:string,password:string){
    type cookieAndMore = {id:string,_expired:string,name:string,access?:'courses'};
    let rtn:Subject<cookieAndMore> = new Subject();
    this.http.post<cookieAndMore>(this.ROOT_URL+'/login',{username:username,password:password},{ withCredentials: true, observe: 'response' })
      .subscribe(res => {
        if (res.status == 201)
          rtn.next(res.body);
        else
          rtn.error('Unexpected code: '+res.status);
      }, err => {
        if (err.status == 401){
          rtn.error(err.error)
        } else {
          console.log(err);
        }
      });
    return rtn;
  }
  logout() {
    let myHeader = {};
    myHeader[SKIP_BLOCK_UI] = 'true';
    this.http.post(this.ROOT_URL+'/logout',null,{ withCredentials: true, observe: 'response', responseType: 'text', headers: {...myHeader} })
      .subscribe(res => {
        if (res.status == 200)
          console.log('logout');
        else
          console.log('logout?');
      }, console.log);
  }
  getUserDataFromBackend(){
    return this.http.get<any>(this.API_URL+'/user2',{ withCredentials: true, observe: 'response' })
      .pipe(take(1));
  }
  getEmployeeListFromBackend(){
    return this.http.get<any[]>(this.API_URL+'/employees',{ withCredentials: true, observe: 'response' })
      .pipe(take(1),map(res => res.body.map(srcEmp => this.backendToEmployee(srcEmp))));
  }
  insertMultipleEmployees(file:any):Observable<boolean>{
    return this.http
      .post(this.API_URL + '/employees', {file: file},{ withCredentials: true, observe: 'response'})
      .pipe(
        map(res => res.status == 202),
        catchError(err => {console.log(err);return of(false)})
      );
  }
  getEmployeesXlsx(){
    window.open(this.API_URL + '/employees/xlsx');
  }
  updateEmployee(dirty: Employee) {
    return this.http.patch<any>(this.API_URL + '/employee/'+dirty.id,this.employeeToBackend(dirty),{ withCredentials: true, observe: 'response' })
      .pipe(map(res => res.status == 200 ? {status: 200, body: this.backendToEmployee(res.body)} : res));
  }
  insertEmployee(newOne: Employee) {
    return this.http.post<any>(this.API_URL + '/employee',this.employeeToBackend(newOne),{ withCredentials: true, observe: 'response' })
      .pipe(map(res => res.status == 201 ? {status: 201, body: this.backendToEmployee(res.body)} : res));
  }
  setEmployeeLeave(emp:Employee) {
    return this.http.patch<any>(this.API_URL + '/employee/'+emp.id,{leavingDate:new Date()},{ withCredentials: true, observe: 'response' });
  }
  setEmployeeActive(emp:Employee){
    let myHeader = {};
    myHeader[SKIP_BLOCK_UI] = 'true';
    return this.http.patch<any>(this.API_URL + '/employee/'+emp.id,{active:true},{ withCredentials: true, observe: 'response', headers: {...myHeader} });
  }
  backendToEmployee(obj:any):Employee{
    return new Employee({
        id: obj.r_id,
        number: obj.employee_number,
        firstName: obj.private_name,
        surname: obj.last_name,
        identity: obj.id,
        site: obj.company,
        department: obj.department?.name,
        role: obj.role?.title,
        leavingDate: obj.leavingDate ? new Date(obj.leavingDate) : null,
        phone: obj.phone,
        email: obj.email,
        languages: obj.languages ? obj.languages.map(langObj => langObj.id) : [],
        comments: obj.comments
      });
  }
  employeeToBackend(emp:Employee):any{
    return {
      employee_number: emp.number,
      private_name: emp.firstName,
      last_name: emp.surname,
      iden: emp.identity,
      company: emp.site,
      department_name: emp.department,
      role_title: emp.role,
      leavingDate: emp.leavingDate,
      phone: emp.phone,
      email: emp.email,
      languages_ids: emp.languages,
      comments: emp.comments
    };
  }

  /************************************************************************************************************ */
  getCourseListFromBackend(){
    return this.http.get<Course[]>(this.ROOT_URL+'/api/courses',{ withCredentials: true, observe: 'response' })
      .pipe(take(1),map(res => res.body));
  }
  getLiteCourseListFromBackend(){
    return this.http.post<Course[]>(this.ROOT_URL+'/courses',null,{ withCredentials: true, observe: 'response' })
      .pipe(take(1),map(res => res.body));
  }
  getEmployeeAssignFromBackend(emp_id:number){
    let myHeader = {};
    myHeader[SKIP_BLOCK_UI] = 'true';
    return this.http.get<Assign[]>(this.API_URL+'/assigns/employee/'+emp_id,{ withCredentials: true, observe: 'response', headers: {...myHeader} })
      .pipe(take(1),map(res => res.body));
  }
  removeAssignOfEmployee(emp_id:number,a_id: number) {
    return this.http.delete(this.API_URL+'/assigns/employee/'+emp_id+'/'+a_id,{ withCredentials: true, observe: 'response' })
      .pipe(take(1),map(res => res.status));
  }
  getEmployeeHistoryFromBackend(emp_id:number) {
    let myHeader = {};
    myHeader[SKIP_BLOCK_UI] = 'true';
    return this.http.get<History[]>(this.API_URL+'/history/employee/'+emp_id,{ withCredentials: true, observe: 'response', headers: {...myHeader} })
      .pipe(take(1),map(res => res.body));
  }
  getCourseAssignFromBackend(course_id:number){
    let myHeader = {};
    myHeader[SKIP_BLOCK_UI] = 'true';
    return this.http.get<Assign[]>(this.API_URL+'/assigns/course/'+course_id,{ withCredentials: true, observe: 'response', headers: {...myHeader} })
      .pipe(take(1),map(res => res.body));
  }
  getCourseHistoryFromBackend(course_id:number){
    let myHeader = {};
    myHeader[SKIP_BLOCK_UI] = 'true';
    return this.http.get<History[]>(this.API_URL+'/history/course/'+course_id,{ withCredentials: true, observe: 'response', headers: {...myHeader} })
      .pipe(take(1),map(res => res.body));
  }
  saveAssignmentsToBackend(c_id:number,obj:{delete:number[],insert:number[],lang:string,send?:any}){
    return this.http.post(this.API_URL+'/assigns/course/'+c_id,obj,{ withCredentials: true, observe: 'response', responseType: 'text' })
      .pipe(take(1),map(res => res.status));
  }

  // connectToCourseHistoryNotification(course_id:number, func:() => void){
  //   let ws = new WebSocket(this.WS_URL);
  //   let formatMessage = (data) => {
  //     try {
  //       const parsed = JSON.parse(data);
  //       return parsed;
  //     } catch (err) {
  //       return data;
  //     }
  //   };
  //   ws.onopen = () => {
  //     ws.send('?course='+course_id);
  //     ws.onmessage = (event) => {
  //       const msg = formatMessage(event.data);
  //       if (msg.reload){
  //         func();
  //       }
  //     };
  //   };
  //   return () => ws.close();
  // }

  // connectToEmployeeHistoryNotification(emp_id:number, func:() => void){
  //   let ws = new WebSocket(this.WS_URL);
  //   let formatMessage = (data) => {
  //     try {
  //       const parsed = JSON.parse(data);
  //       return parsed;
  //     } catch (err) {
  //       return data;
  //     }
  //   };
  //   ws.onopen = () => {
  //     ws.send('?employee='+emp_id);
  //     ws.onmessage = (event) => {
  //       const msg = formatMessage(event.data);
  //       if (msg.reload){
  //         func();
  //       }
  //     };
  //   };
  //   return () => ws.close();
  // }

  /************************************************************************************************************* */
  getJson(company_id: string, course_id:number){
    return this.http.get<any>(this.ROOT_URL + '/'+company_id+'/images/start'+course_id+'.json',{ withCredentials: true, observe: 'response', responseType: 'json' });
  }
  updateSessionUser(body:any){
    return this.http.post(this.ROOT_URL + '/notify2',body,{ withCredentials: true, observe: 'events', responseType: 'text', reportProgress: true});
  }
  setCourseAccess(course_id: number) {
    return this.http.get(this.ROOT_URL + '/access/'+course_id,{ withCredentials: true, observe: 'response', responseType: 'text' }).pipe(take(1));
  }
  getOptionsFromUrl(url:string){
    let myHeader = {};
    myHeader[SKIP_BLOCK_UI] = 'true';
    return this.http.get<any[]>(url,{ withCredentials: true, responseType: 'json', headers: myHeader });
  }
  updateSessionPriorityUser(body:any){
    return this.http.post(this.ROOT_URL + '/notify3',body,{ withCredentials: true, observe: 'events', responseType: 'text', reportProgress: true});
  }
}

export function filterHttpRequest(req:HttpRequest<any>): boolean {
  return req.headers.get(SKIP_BLOCK_UI) !== null;
}