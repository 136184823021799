import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class shareService {

  public courseId$: BehaviorSubject<number> = new BehaviorSubject(null);
  public language$: BehaviorSubject<{id: string, name: string, name_h: string}> = new BehaviorSubject(null);
  public idnum$: BehaviorSubject<string> = new BehaviorSubject(null);
  public employeeName$: BehaviorSubject<string> = new BehaviorSubject(null);
  public company$: BehaviorSubject<{id: string, name: string}> = new BehaviorSubject(null);

  constructor() { }
}
