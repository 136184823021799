<div class="wrap-form">
    <div class="gray-rect">
        <div class="icon">
            <img src="assets/green-for-avatar.svg">
            <div class="inner-icon">
                <img src="assets/login-avatar.png">
            </div>
        </div>

        <p class="logintitle">התחבר</p>

        <input type="email" style="position:absolute;top:-100vh;">
        <input type="password" style="position:absolute;top:-100vh;">

        <div *ngIf="{}; let obj" class="text-input-wrapper" [attr.childfocus]=obj.focus>
            <input type="text" (focus)="obj.focus = true" (blur)="obj.focus = false" [(ngModel)]="username" #input1>
            <div class="label" [attr.hasText]="username && username.length > 0" (click)="input1.focus()">שם משתמש</div>
            <div class="gray-square"><img src="assets/avatar-for-gray-square.svg"></div>
        </div>

        <div class="password-input-wrapper" *ngIf="{}; let obj" [attr.childfocus]=obj.focus>
            <input type="password" (focus)="obj.focus = true" (blur)="obj.focus = false" (keyup.enter)="onClick()" [(ngModel)]="password" #input2>
            <div class="label" [attr.hasText]="password && password.length > 0" (click)="input2.focus();">סיסמה</div>
            <div class="gray-square"><img src="assets/lock-for-gray-square.svg"></div>
        </div>

        <button (click)="onClick()">כניסה</button>
    </div>
</div>