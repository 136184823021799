<div class="icon">
    <img src="assets/bluewy.svg">
    <div class="inner-icon">
        <img src="assets/archive.png">
    </div>
</div>

<div class="list-section">
    <span class="title">ארכיון - רשימת עובדים שעזבו</span>
    <span class="sub-title">לחץ על העיגולים לבחירת עובדים</span>
    <div #filter class="filter-wrapper" *ngIf="storeHolder.store.filterIsOpen" [ngStyle]="{'right': storeHolder.store.filterRight + 'px' }" [ngClass]="{'higher' : higher, 'wider' : wider}">
        <div class="search-wrapper">
            <input type="text" placeholder="חיפוש" (keyup)="onFilterSearchChange($event)" [(ngModel)]="storeHolder.filterSearchValue">
        </div>
        <checkbox class="select-all" (check-click)="checkAllFilter()" [status]="isAllFilterChecked()" [text]="'בחר הכל'"></checkbox>
        <div class="options-wrapper">
            <div *ngFor="let op of storeHolder.store.searchValues">
                <checkbox class="select-all" (check-click)="doCheck(op)" [status]="op.check ? 'checked' : undefined" [text]="op.value"></checkbox>
            </div>
        </div>
        <div class="btn-wrapper">
            <div class="filter-btn" (click)="closeFilter(true)">סינון</div>
        </div>
    </div>
    <div class="head-row">
        <div style="width: 40px;" class="align-center"></div>
        <div style="width: 78px;">
            <span>מ. עובד</span>
            <img src="assets/filter-arrow.png" (click)="openFilter('number')">
        </div>
        <div style="width: 90px;">
            <span>שם פרטי</span>
            <img src="assets/filter-arrow.png" (click)="openFilter('firstName')">
        </div>
        <div style="width: 110px;">
            <span>שם משפחה</span>
            <img src="assets/filter-arrow.png" (click)="openFilter('surname')">
        </div>
        <div style="width: 110px;">
            <span>תעודת זהות</span>
            <img src="assets/filter-arrow.png" (click)="openFilter('identity')">
        </div>
        <div style="width: 73px;">
            <span>סניף</span>
            <img src="assets/filter-arrow.png" (click)="openFilter('site')">
        </div>
        <div style="width: 80px;">
            <span>מחלקה</span>
            <img src="assets/filter-arrow.png" (click)="openFilter('department')">
        </div>
        <div style="width: 85px;">
            <span>תפקיד</span>
            <img src="assets/filter-arrow.png" (click)="openFilter('role')">
        </div>
        <div class="leaving-width">
            <span>תאריך עזיבה</span>
            <img src="assets/filter-arrow.png" (click)="openFilter('leavingDate')">
        </div>
    </div>
    <div class="rows-wrapper-wrapper">
        <div class="rows-wrapper hide-scrollbar">
            <div *ngFor="let emp of storeHolder.store.displayEmployees" class="single-row" (click)="selectClick(emp)">
                <div style="width: 40px;" class="checkwrap align-center" [ngClass]="{'selected':isSelected(emp)}">
                    <span class="checkmark" style="margin: 0;" [ngStyle]="{ 'backgroundColor' : isSelected(emp) ? 'rgba(22, 159, 178, 1)' : undefined }"></span>
                </div>
                <div class="row-detail-wrapper">
                    <div style="width: 78px;">
                        <span [attr.title]="emp.number">{{emp.number}}</span>
                    </div>
                    <div style="width: 90px;">
                        <span [attr.title]="emp.firstName">{{emp.firstName}}</span>
                    </div>
                    <div style="width: 110px;">
                        <span [attr.title]="emp.surname">{{emp.surname}}</span>
                    </div>
                    <div style="width: 110px;">
                        <span [attr.title]="emp.identity">{{emp.identity}}</span>
                    </div>
                    <div style="width: 73px;">
                        <span [attr.title]="emp.site">{{emp.site}}</span>
                    </div>
                    <div style="width: 80px;">
                        <span [attr.title]="emp.department">{{emp.department}}</span>
                    </div>
                    <div style="width: 85px;">
                        <span [attr.title]="emp.role">{{emp.role}}</span>
                    </div>
                </div>
                <div class="leaving-wrap align-center leaving-width" [ngClass]="{'selected':isSelected(emp)}">
                    <span [attr.title]="emp.leavingDate | date: 'dd.MM.yy' ">{{emp.leavingDate | date: 'dd.MM.yy' }}</span>
                </div>
            </div>
        </div>
    </div>
    <div class="bottom-btns-wrapper">
        <div class="back-to-list-btn" [routerLink]="'../list'">
            <span>חזרה לכל העובדים</span>
        </div>
        <div class="turn-active-btn" [ngStyle]="{'backgroundColor': selectedEmployees?.length > 0 ? 'rgba(202, 249, 130, 1)' : 'inherit' }" (click)="setSelectedAsActive()">
            <span>החזר למצב פעיל</span>
        </div>
    </div>
</div>

<div class="single-section">
    <img src="assets/person.png">
</div>