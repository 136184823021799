import { DatePipe } from '@angular/common';
import { createSelector } from '@ngrx/store';
import { AppState } from '../app/app.state';
import { selectCourseList, selectCourseListExceptEveryone } from '../course/course.selector';
import { EmployeePlus } from '../employee/employee.model';
import { selectEmployeeList } from '../employee/employee.selector';
import { EmployeeState } from '../employee/employee.state';
import { selectCourseHistory, selectEmployeeHistory } from '../history/history.selector';
import { AssignState } from './assign.state';

const selectAssigns = (state:AppState) => state.assign;

export const selectEmployeeAssign = createSelector(
    selectAssigns,
    selectCourseListExceptEveryone,
    selectEmployeeHistory,
    (state:AssignState, courses, histories) => state.employee.list.filter(a => !!a.sent).map(assign => {
        let course = courses.find(c => c.id == assign.course);
        let lastHistory = histories.find(h => h.course.id == assign.course)?.list[0];
        if (course){
            if (lastHistory && (new Date(lastHistory.date).getTime() > new Date(assign.sent).getTime())){
                return null;
            }
            return {...assign, title: course.title }
        } else {
            return null;
        }
    }).filter(a => !!a)
);
export const selectEmployeeOfAssign = createSelector(
    selectAssigns,
    (state:AssignState) => state.employee.subject
);
export const selectCourseNoSentAssign = createSelector(
    selectAssigns,
    selectEmployeeList,
    (state:AssignState, employees) => state.course.list.filter(a => !a.sent).map(assign => {
        let employee = employees.find(e => e.id == assign.employee);
        if (employee){
            return {...assign, name: employee.firstName + ' ' + employee.surname }
        } else {
            return {...assign, name: undefined }
        }
    }).filter(a => a.name !== undefined)
);
const selectCourseSentAssign = createSelector(
    selectAssigns,
    selectEmployeeList,
    (state:AssignState, employees) => state.course.list.filter(a => !!a.sent)
);
export const combineAssignWithHistory = createSelector(
    selectEmployeeList,
    selectCourseSentAssign,
    selectCourseHistory,
    (employees, assigns, histories) => employees.map(employee => {
        let rtn = new EmployeePlus(employee);
        let assign = assigns.find(a => a.employee == employee.id);
        let history = histories.find(h => h.employee.id == employee.id);
        if (assign && assign.sent){
            if (history){
                if (new Date(assign.sent).getTime() > new Date(history.last.date).getTime()){
                    rtn.statusText = "נשלח ב- "+(new DatePipe('he')).transform(assign.sent,'dd.MM.yy');
                } else {
                    rtn.statusText = history.last.pass ? 'עבר בהצלחה' : 'לא עבר';
                    rtn.statusColor = history.last.pass ? 'rgba(202, 249, 130, 1)' : 'rgba(247, 197, 138, 1)';
                }
            } else {
                rtn.statusText = "נשלח ב- "+(new DatePipe('he')).transform(assign.sent,'dd.MM.yy');
            }
        } else if (history){
            rtn.statusText = history.last.pass ? 'עבר בהצלחה' : 'לא עבר';
            rtn.statusColor = history.last.pass ? 'rgba(202, 249, 130, 1)' : 'rgba(247, 197, 138, 1)';
        }
        return rtn;
    })
);
 