import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import { UserService } from 'src/app/user-service/user.service';

@Component({
  selector: 'app-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.scss']
})
export class SidenavComponent implements OnInit {
  options:{text:string, src:string, chosen:boolean, route:string}[] = [
    {text:'רשימת עובדים',src:'assets/option-1.png',chosen:false, route:'list'},
    {text:'זימון לומדה',src:'assets/option-2.png',chosen:false, route:'assign'},
    {text:'הוספת עובד',src:'assets/option-3.png',chosen:false, route:'add'},
    {text:'ארכיון עובדים',src:'assets/option-4.png',chosen:false, route:'archive'}
  ];
  constructor(private router: Router, public user:UserService) { }

  ngOnInit(): void {
    let selectNavOptionByUrl = (url:string) => {
      let someOptionHasSelected = false;
      for (let opt of this.options){
        if (url.includes(opt.route)){
          opt.chosen = true;
          someOptionHasSelected = true;
        } else {
          opt.chosen = false;
        }
      }
      if (!someOptionHasSelected && url == '/'){
        this.options[0].chosen = true;
      }
    }
    this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe((event:NavigationEnd) => {
        selectNavOptionByUrl(event.url);
      });
    // call manually on first load
    selectNavOptionByUrl(this.router.url);
  }

}
