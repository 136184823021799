import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { ApiService } from '../api-service/api.service';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  name:string;
  clientLogoUrl:string;
  company_id:string;
  company_name:string;
  isLogin$:BehaviorSubject<{val:boolean,access?:'courses'}>;
  constructor(private api: ApiService) {
    let initial;
    if (this.isLoginStillValid())
      initial = {val:true};
    else if (this.isLoginForCoursesStillValid())
      initial = {val:true,access:'courses'};
    else
      initial = {val:false};
    this.isLogin$ = new BehaviorSubject(initial);
    if (this.isLogin$.value.val){
      this.getUserData();
    }
    // if (this.isLogin() || this.isLoginForCourses()){
    //   this.getUserData();
    // }
  }
  getUserData():void{
    this.api.getUserDataFromBackend().subscribe(res => {
      this.name = res.body.name;
      this.company_id = res.body.company_id;
      this.clientLogoUrl = 'https://panel.momenteken-college.co.il/'+this.company_id+'/images/logo.png';//res.body.imgUrl;
      this.company_name = res.body.company_name;
    });
  }
  getName(){
    return this.name;
  }
  login(username:string,password:string):Observable<boolean>{
    return this.api.login(username,password)
      .pipe(
        map(cookieAndMore => {
          if (cookieAndMore){
            if (!cookieAndMore.access){
              localStorage.setItem('session-token',cookieAndMore.id);
              localStorage.setItem('session-expired',cookieAndMore._expired);
              this.isLogin$.next({val:true});
            } else /*if (cookieAndMore.access === 'courses')*/{
              localStorage.setItem('session-token-1',cookieAndMore.id);
              localStorage.setItem('session-expired-1',cookieAndMore._expired);
              this.isLogin$.next({val:true, access:'courses'});
            }
          }
          return !!cookieAndMore
        })
      );
  }
  private isLoginStillValid(){
    if (!localStorage.getItem('session-token')){
      localStorage.removeItem('session-token');
      localStorage.removeItem('session-expired');
    } else {
      let now = Date.now();
      let exp = Date.parse(localStorage.getItem('session-expired'));
      // let expVal = localStorage.getItem('session-expired');
      // let exp = Date.parse(expVal);
      // if (isNaN(exp) && !isNaN(+expVal)){
      //   exp = +expVal;
      // }
      if (isNaN(exp) || exp - now < 0){
        localStorage.removeItem('session-token');
        localStorage.removeItem('session-expired');
      }
    }
    return !!localStorage.getItem('session-token')
  }
  private isLoginForCoursesStillValid(){
    if (!localStorage.getItem('session-token-1')){
      localStorage.removeItem('session-token-1');
      localStorage.removeItem('session-expired-1');
    } else {
      let now = Date.now();
      let exp = Date.parse(localStorage.getItem('session-expired-1'));
      if (isNaN(exp) || exp - now < 0){
        localStorage.removeItem('session-token-1');
        localStorage.removeItem('session-expired-1');
      }
    }
    return !!localStorage.getItem('session-token-1')
  }
  logout() {
    localStorage.removeItem('session-token');
    localStorage.removeItem('session-expired');
    localStorage.removeItem('session-token-1');
    localStorage.removeItem('session-expired-1');
    this.isLogin$.next({val:false});
    this.api.logout();
    this.name = null;
    this.clientLogoUrl = null;
    this.company_id = null;
    this.company_name = null;
  }
}