import { Component, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core';
import { Employee } from 'src/app/stores/employee/employee.model';
import { EmployeeService } from '../services/employee.service';
import { Mutex } from 'async-mutex';

const fields = {
  number:137,
  firstName:238,
  surname:363,
  identity:485,
  site:560,
  department:666,
  role:756,
  leavingDate:892
}

@Component({
  selector: 'app-employee-archive',
  templateUrl: './employee-archive.component.html',
  styleUrls: ['./employee-archive.component.scss']
})
export class EmployeeArchiveComponent implements OnInit {

  higher:boolean;
  wider:boolean;
  preventSequenceOpening:boolean;
  storeHolder:any;
  private _selectedEmployees:Employee[];
  private _mutex:Mutex = new Mutex();
  set selectedEmployees(arr:Employee[]){
    this._mutex.acquire().then(
      release => {
        this._selectedEmployees = arr;
        release();
      }
    );
  }
  get selectedEmployees(){
    if (this._mutex.isLocked()){
      return [];
    } else {
      return this._selectedEmployees;
    }
  }
  
  @ViewChild('filter') filterElement:ElementRef;
  constructor(private employeeService: EmployeeService) { }

  ngOnInit(): void {
    this.storeHolder = this.employeeService.initialFilterList(fields,true);
    this.selectedEmployees = [];
  }

  @HostListener('document:mouseup', ['$event.target'])
  onClick(target) {
    if (this.filterElement){
      const clickedInside = this.filterElement.nativeElement.contains(target);
      if (!clickedInside) {
        this.closeFilter(false);
        this.preventSequenceOpening = true;
        window.setInterval(()=>{this.preventSequenceOpening = false;},100);
      }
    }
  }

  openFilter(key:string){
    if (this.preventSequenceOpening){
      return;
    }
    window.setTimeout(() => {this.higher = true}, 10)
    window.setTimeout(() => {this.wider = true}, 160)
    this.employeeService.openFilter(key);
  }

  closeFilter(bool:boolean){
    let onlyOneEmployeeFilter = this.employeeService.closeFilter(bool);
    if (onlyOneEmployeeFilter && !this.isSelected(onlyOneEmployeeFilter)){
      this.selectClick(onlyOneEmployeeFilter);
    }
    this.higher = undefined;
    this.wider = undefined;
  }

  checkAllFilter(){
    this.employeeService.checkAllFilter();
  }

  doCheck(op: {value:string,check:boolean}){
    this.employeeService.doCheck(op);
  }

  isAllFilterChecked(){
    return this.employeeService.isAllFilterChecked();
  }

  onFilterSearchChange(event){
    if (event.keyCode == 13){//'Enter' event
      this.closeFilter(true);
      return;
    }
    if (!this.storeHolder.filterSearchPrevValue){
      this.employeeService.checkAllFilter('checked');
    } else if (!this.storeHolder.filterSearchValue){
      this.employeeService.checkAllFilter('baboom');
    }
    this.employeeService.applySearchFilter();
    this.storeHolder.filterSearchPrevValue = this.storeHolder.filterSearchValue;
  }

  isSelected(emp:Employee){
    return this.selectedEmployees.includes(emp);
  }

  selectClick(emp:Employee){
    if (this.isSelected(emp)){
      this.selectedEmployees = this.selectedEmployees.filter(e => e !== emp);
    } else {
      this.selectedEmployees.push(emp);
    }
  }

  setSelectedAsActive(){
    for (let emp of this.selectedEmployees){
      this.employeeService.markActive(emp).then(bool => {
        if (bool){
          this.selectedEmployees = this.selectedEmployees.filter(e => e !== emp);
        }
      }).catch(reason => alert(reason.error));
    }
  }
}
